import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumToDisplayPipe } from './pipes/enum-to-display.pipe';
import { FromUtcDatePipe } from './pipes/from-utc-date.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { HighlightPipe } from './pipes/highlight-pipe.pipe';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { CompanySelectorComponent } from './company-selector/company-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { SupplierSelectorComponent } from './supplier-selector/supplier-selector.component';
import { RentalTypeSelectorComponent } from './rental-type-selector/rental-type-selector.component';
import { ProductSelectorComponent } from './product-selector/product-selector.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { PriceTypeSelectorComponent } from './price-type-selector/price-type-selector.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DeletDialogComponent } from './delet-dialog/delet-dialog.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { ButtonHedderComponent } from './button-hedder/button-hedder.component';
import { OptionDialogComponent } from './option-dialog/option-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ActionIconButtonsComponent } from './action-icon-buttons/action-icon-buttons.component';
import { SimpelInputDialogComponent } from './simpel-input-dialog/simpel-input-dialog.component';



@NgModule({
  declarations: [
    EnumToDisplayPipe,
    FromUtcDatePipe,
    EnumToArrayPipe,
    HighlightPipe,
    ConfirmationDialogComponent,
    CompanySelectorComponent,
    SupplierSelectorComponent,
    RentalTypeSelectorComponent,
    ProductSelectorComponent,
    PriceTypeSelectorComponent,
    DeletDialogComponent,
    DateTimePickerComponent,
    ButtonHedderComponent,
    OptionDialogComponent,
    ActionIconButtonsComponent,
    SimpelInputDialogComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatNativeDateModule,
    MatCheckboxModule
  ],
  exports: [
    EnumToDisplayPipe,
    FromUtcDatePipe,
    EnumToArrayPipe,
    HighlightPipe,
    ConfirmationDialogComponent,
    CompanySelectorComponent,
    SupplierSelectorComponent,
    RentalTypeSelectorComponent,
    ProductSelectorComponent,
    PriceTypeSelectorComponent,
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatIconModule,
    MatNativeDateModule,
    FormsModule,
    DateTimePickerComponent,
    ButtonHedderComponent,
    ActionIconButtonsComponent,
    SimpelInputDialogComponent
  ]
})
export class SharedLibaryModule { }
