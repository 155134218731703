import { Product } from './../../_models/product';
import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { OrderSubRow, Retailor, Invoice } from 'src/app/_models/invoice';
import { PriceType } from 'src/app/_models/price';
import { PriceService } from 'src/app/products/_services/price.service';
import { PriceDevelopment } from 'src/app/_models/priceDevelopment';
import { PriceDevelopmentService } from '../_services/price-development.service';

@Component({
  selector: 'app-compare-price',
  templateUrl: './compare-price.component.html',
  styleUrls: ['./compare-price.component.scss'],
})
export class ComparePriceComponent implements OnInit, OnChanges {
  priviusRow: OrderSubRow | undefined;
  invoice: Invoice | undefined;
  priceDevelopment: PriceDevelopment | undefined;
  compareProducts: Product[];
  priceTypes = PriceType;
  retailors = Retailor;

  @Input() orderSubRowId = 0;
  @Input() productId = 0;
  @Input() priceType = PriceType.None;
  @Input() show = false;

  @Input() ComapreDate = '';
  @Input() CompanyId = 0;

  constructor(
    private priceDevelopmentService: PriceDevelopmentService,
    private priceService: PriceService
  ) {
    this.compareProducts = [];
  }

  ngOnInit(): void {
    this.getPriceDevelopment();
    this.getCompareProducts();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['productId'] || changes['priceType']) {
      this.getPriceDevelopment();
      this.getCompareProducts();
    }
  }

  getPriceDevelopment() {
    if (this.orderSubRowId) {
      this.priceDevelopmentService
        .getExtendedPriceDevelopment(this.orderSubRowId)
        .subscribe((x) => {
          this.invoice = x.invoice;
          this.priviusRow = x.priviusRow;
          this.priceDevelopment = x.priceDevelopment;
        });
    } else if (this.ComapreDate) {
      if (!this.productId) return;
      const date = new Date(this.ComapreDate);
      this.priceDevelopmentService
        .getExtendedPriceDevelopmentByProduct(
          this.productId,
          this.CompanyId,
          date,
          this.priceType
        )
        .subscribe((x) => {
          this.invoice = x.invoice;
          this.priviusRow = x.priviusRow;
          this.priceDevelopment = x.priceDevelopment;
        });
    }
  }

  getCompareProducts() {
    if (this.productId) {
      this.priceService
        .getLatestCompetorProductPriceByProductId(
          this.productId,
          this.priceType
        )
        .subscribe((x) => (this.compareProducts = x));
    }
  }
}
