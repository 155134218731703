import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../../_services/base.service';
import { MessageService } from '../../_services/message.service';
import { environment } from 'src/environments/environment';
import { Product } from '../../_models/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService {

  constructor(
    private http: HttpClient,
    public messageService: MessageService,
    public dialog: MatDialog
  ) {
    super(messageService, dialog);
  }

  public upploadeProductLIst(retailor: number, formData: FormData, timeOfProduct: number, company_Id: number): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/api/Product/UpploadeProductList?retailor=${retailor}&&date=${timeOfProduct}&&company_Id=${company_Id}`, formData)
      .pipe(
        catchError(this.handleError<boolean>('UpploadeProductLIst'))
      );
  }

  public upploadeProductUpdate(retailor: number, formData: FormData): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/api/Product/upploadeProductUpdate?retailor=${retailor}`, formData)
      .pipe(
        catchError(this.handleError<boolean>('UpploadeProductLIst'))
      );
  }

  public findPossibleMatchesOfProduct(searchTerm: string, retailor: number): Observable<Product[]> {
    return this.http.get<Product[]>(`${environment.apiUrl}/api/Product/findPossibleMatchesOfProduct?retailor=${retailor}&&searchTerm=${searchTerm}`)
      .pipe(
        catchError(this.handleError<Product[]>('doInvoiceExist'))
      );
  }

  public getSystemStatus(): Observable<string> {
    return this.http.get(`${environment.apiUrl}/api/Product/getSystemStatus`, { responseType: 'text' })
      .pipe(
        catchError(this.handleError<string>('getSystemStatus'))
      );
  }

  public getAmountOfProductsPerSupplier(): Observable<string> {
    return this.http.get(`${environment.apiUrl}/api/Product/getAmountOfProductsPerSupplier`, { responseType: 'text' })
      .pipe(
        catchError(this.handleError<string>('AmountOfProductsPerSupplier'))
      );
  }

  public getLastProductUppdateForAllSupplier(): Observable<string> {
    return this.http.get(`${environment.apiUrl}/api/Product/GetLastProductUppdateForAllSupplier`, { responseType: 'text' })
      .pipe(
        catchError(this.handleError<string>('GetLastProductUppdateForAllSupplier'))
      );
  }

  public getLastPriceUppdateForAllSupplier(): Observable<string> {
    return this.http.get(`${environment.apiUrl}/api/Product/GetLastPriceUppdateForAllSupplier`, { responseType: 'text' })
      .pipe(
        catchError(this.handleError<string>('GetLastPriceUppdateForAllSupplier'))
      );
  }

  public get(id: number): Observable<Product> {
    return this.http.get<Product>(`${environment.apiUrl}/api/Product/get/${id}`)
      .pipe(
        catchError(this.handleError<Product>('getSystemStatus'))
      );
  }
}
