import { UserType } from './../_models/user';
import { Component, OnInit } from '@angular/core';
import { AccountService } from '../_services/account.service';
import { Token } from '../_models/token';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  isLoggdIn = false;
  UserType = UserType;

  constructor(public accountService: AccountService) { }

  ngOnInit(): void {
    this.accountService.token.subscribe(x => {
      this.isLoggdIn = this.ChekisLoggdIn(x);
    });
  }

  logout() {
    this.accountService.logout();
  }

  ChekisLoggdIn(token: Token) {
    // console.log('ChekisLoggdIn');
    // console.log(token);
    if (token && token.experation) {
      return true;
    }
    return false;
  }

  showIfIsInUserType(userTypes: UserType[]): boolean {
    let index = userTypes.findIndex(x => x === this.accountService.userType);
    if (index > -1){
      return true;
    } else{
      return false;
    }
  }
}
