<h1 mat-dialog-title>{{data.hedder}}</h1>
<div mat-dialog-content>
  <p>{{data.qestion}}</p>
  <mat-form-field appearance="fill">
    <mat-label>{{data.hint}}</mat-label>
    <input matInput cdkFocusInitial [(ngModel)]="data.answer">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="onNoClick()">Avbryt</button>
  <button mat-raised-button [mat-dialog-close]="data.answer" >Ok</button>
</div>
