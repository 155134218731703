<nav class="bg-primary">
  <div>
    <a class="logo" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">BYTEX</a>
    <span class="premium">Premium</span>
    <span class="beta">Beta</span>
  </div>
  <div *ngIf="isLoggdIn" class="wrap">
    <div>
      <button *ngIf="showIfIsInUserType([UserType.SysAdmin, UserType.CustomerUser])" mat-small mat-flat-button
        color="primary" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Hem</button>
      <button *ngIf="showIfIsInUserType([UserType.SysAdmin])" mat-flat-button color="primary" routerLink="/admin"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Admin</button>
      <button *ngIf="showIfIsInUserType([UserType.SysAdmin, UserType.CustomerUser])" mat-flat-button color="primary"
        routerLink="/admin/users" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Användare</button>
      <button *ngIf="showIfIsInUserType([UserType.SysAdmin, UserType.CustomerUser])" mat-flat-button color="primary"
        routerLink="/invoice/invoices" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">Fakturor</button>
      <button *ngIf="showIfIsInUserType([UserType.SysAdmin, UserType.CustomerUser])" mat-flat-button color="primary"
        routerLink="/invoice/re-invoices" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">Underlag</button>
      <button *ngIf="showIfIsInUserType([UserType.SysAdmin, UserType.CustomerUser])" mat-flat-button color="primary"
        routerLink="/project/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Projekt</button>
      <button *ngIf="showIfIsInUserType([UserType.SysAdmin, UserType.CustomerUser, UserType.TimeReportingUser])"
        mat-flat-button color="primary" routerLink="/punch-clock/tid-profil" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">Stämpelklocka</button>
      <button *ngIf="showIfIsInUserType([UserType.SysAdmin, UserType.CustomerUser, UserType.PunchClockUser])"
        mat-flat-button color="primary" routerLink="/punch-clock/plats-valjare" routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">
        Ipad projektplats
      </button>
      <button *ngIf="showIfIsInUserType([UserType.SysAdmin])" mat-flat-button color="primary"
        routerLink="/punch-clock/test" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Test</button>
<app-nav-button-menu [buttonName]="'Projekt'" [menuOptions]="[
  { name: 'Projekt', route: '/project/projects-list' },
  { name: 'Projekt platser', route: '/project/locations/1' },
  { name: 'Almänna Platser', route: '/project/locations/2' }
]"></app-nav-button-menu>
    </div>
    <div>
      <button mat-flat-button color="primary" (click)="logout()">Logga ut</button>
    </div>
  </div>
</nav>