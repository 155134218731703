import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PriceService } from 'src/app/products/_services/price.service';
import { MessageService } from 'src/app/_services/message.service';
import {
  Order,
  OrderRow,
  Invoice,
  OrderSubRow,
  InvoiceAppliedRow,
} from 'src/app/_models/invoice';
import { Price, PriceType } from 'src/app/_models/price';
import { Product } from 'src/app/_models/product';
import { ReInvoiceWithoutInvoice } from 'src/app/_models/reInvoiceWithoutInvoice';
import { InvoiceService } from '../_services/invoice.service';
import { ReInvoiceService } from '../_services/re-invoice.service';
import { Router } from '@angular/router';

@Component({
  templateUrl: './create-re-invoice.component.html',
  styleUrls: ['./create-re-invoice.component.scss'],
})
export class CreateReInvoiceComponent implements OnInit {
  reInvoiceWithoutInvoice: ReInvoiceWithoutInvoice =
    {} as ReInvoiceWithoutInvoice;
  invoice: Invoice = {} as Invoice;
  date = new UntypedFormControl(new Date());
  priceTypes = PriceType;

  constructor(
    private invoiceService: InvoiceService,
    private reinvoiceService: ReInvoiceService,
    private messageService: MessageService,
    private priceService: PriceService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.invoice.orders = [];
    this.invoice.invoiceAppliedRows = [];
    this.addOrder();
    this.recalc();
  }

  addOrder() {
    this.invoiceService.addOrder(this.invoice);
  }

  addOrderRow(order: Order) {
    this.invoiceService.addOrderRow(order);
  }

  addOrderSubRow(orderRow: OrderRow) {
    this.invoiceService.addOrderSubRow(orderRow);
  }

  addInvoiceRow() {
    this.invoiceService.addInvoiceRow(this.invoice);
  }

  deleteOrder(order: Order) {
    this.invoiceService.deleteOrder(this.invoice, order);
  }

  deleteOrderRow(orderRow: OrderRow) {
    this.invoiceService.deleteOrderRow(this.invoice, orderRow);
  }

  deleteOrderSubRow(orderSubRow: OrderSubRow) {
    this.invoiceService.deleteOrderSubRow(this.invoice, orderSubRow);
  }

  deleteInvoiceAppliedRow(invoiceAppliedRow: InvoiceAppliedRow) {
    this.invoiceService.deleteInvoiceAppliedRow(
      this.invoice,
      invoiceAppliedRow
    );
  }

  create() {
    this.messageService.setSpinner(true);
    console.log(this.invoice);
    this.recalc();

    this.reInvoiceWithoutInvoice.invoiceDate = this.date.value;
    this.reInvoiceWithoutInvoice.orders = this.invoice.orders;
    this.reInvoiceWithoutInvoice.invoiceAppliedRows =
      this.invoice.invoiceAppliedRows;

    this.reinvoiceService
      .createReInvoiceWithoutInvoice(this.reInvoiceWithoutInvoice)
      .subscribe(
        (x) => {
          this.messageService.endSpinnerWithMessage('Skapad');
          this.router.navigateByUrl('invoice/re-invoice-editor/' + x);
        },
        (error) => {
          this.messageService.endSpinnerWithMessage(error);
        }
      );
  }

  recalc() {
    this.invoiceService.updateTotalsOnInvoice(this.invoice, true);
  }

  recalcPrice(price: Price) {
    this.priceService.recalcPrice(price);
    this.recalc();
  }

  getPriceByType(orderSubRow: OrderSubRow): void {
    console.log(orderSubRow.customerPrice.priceType);
    this.priceService
      .getLatestPriceByProductIdAndPriceType(
        orderSubRow.customerPrice.product_Id,
        orderSubRow.customerPrice.priceType
      )
      .subscribe((x) => {
        if (x) {
          orderSubRow.customerPrice.aPrice = x.aPrice;
        }
      });
  }

  selectOption(prod: Product, row: OrderRow) {
    row.articleNo = prod.articleNo;
    row.productName = prod.productName;
    this.priceService
      .getLatestPriceByProductId(prod.productId)
      .subscribe((x) => {
        if (x) {
          let subRow = row.subRows[0];
          subRow.customerPrice.priceType = x.priceType;
          subRow.customerPrice.aPrice = x.aPrice;
          subRow.customerPrice.product_Id = prod.productId;

          if (x.discountOfAPrice) {
            subRow.customerPrice.discountOfAPrice = -x.discountOfAPrice * 100;
          }

          subRow.showPriceDevelopment = true;
          this.recalcPrice(subRow.customerPrice);
        }
      });
  }
}
