import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-option-dialog',
  templateUrl: './option-dialog.component.html',
  styleUrls: ['./option-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: OptionDialogData, private mdDialogRef: MatDialogRef<OptionDialogComponent>) {}

  cancel() {
    this.mdDialogRef.close(false);
  }

  confirm() {
    this.mdDialogRef.close(this.data.options);
  }
}

export interface OptionDialogData {
  title: string;
  message: string;
  options: OptionDialogOptions[];
}

export interface OptionDialogOptions {
  label:string;
  checked:boolean;
}
