import { Component, OnInit } from '@angular/core';
import { InvoiceService } from '../_services/invoice.service';
import { MessageService } from 'src/app/_services/message.service';
import { FileInformation } from 'src/app/_models/fileInformation';

@Component({
  selector: 'app-failed-uploades',
  templateUrl: './failed-uploades.component.html',
  styleUrls: ['./failed-uploades.component.scss']
})
export class FailedUploadesComponent implements OnInit {

  faildFiles: FileInformation[] = [];

  constructor(
    private invoiceService: InvoiceService,
    public messageService: MessageService) { }

  ngOnInit(): void {
    this.loadList();
  }

  getFile(fileLink: string) {
    this.invoiceService.downloadErrorInvoice(fileLink)
      .subscribe(x => this.invoiceService.downloadFileLocaly(x));
  }

  delet(fileLink: string) {
    this.messageService.deletConfirmation()
      .subscribe(confirmed => {
        if (confirmed) {
          //do something if confirmed is true
          this.invoiceService.deletErrorInvoice(fileLink)
            .subscribe(x => this.loadList());
        } else {
          console.log(false);
        }
      });
  }

  getLink(fileInfo:FileInformation):string{
    return fileInfo.filePath + fileInfo.fileName + '.' + fileInfo.fileType;
  }

  loadList() {
      this.invoiceService.downloadErrorInvoicesList()
      .subscribe(x => this.faildFiles = x.sort(function(a,b){
        return +new Date(a.createdOn) - +new Date(b.createdOn);
      }))
  }

}
