import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Invoice } from '../_models/invoice';
import { TableWrapper } from '../_models/tableWrapper';
import { ReInvoiceService } from './_services/re-invoice.service';

export class ReInvoiceDataSource implements DataSource<Invoice> {
  private invoiecsSubject = new BehaviorSubject<Invoice[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  public paginator!: MatPaginator;

  constructor(private reInvoiceService: ReInvoiceService) {}

  connect(collectionViewer: CollectionViewer): Observable<readonly Invoice[]> {
    return this.invoiecsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.invoiecsSubject.complete();
    this.loadingSubject.complete();
  }

  load(
    companyID = 0,
    retailor = 0,
    filter = '',
    sortDirection = 'asc',
    sortCollom = ''
  ) {
    this.loadingSubject.next(true);

    this.reInvoiceService
      .findinvoices(
        companyID,
        retailor,
        filter,
        sortDirection,
        sortCollom,
        this.paginator?.pageIndex || 0,
        this.paginator?.pageSize || 10
      )
      .pipe(
        catchError(() =>
          of({
            data: [],
            totalResults: 0,
          } as TableWrapper)
        ),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((x) => {
        this.invoiecsSubject.next(x.data);
        if (this.paginator != undefined) {
          this.paginator.length = x.totalResults;
        }
      });
  }
}
