import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AccountService } from '../_services/account.service';
import { catchError, tap } from 'rxjs/operators';
import { UserType } from '../_models/user';
import { environment } from 'src/environments/environment';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  approvedApi:string;

  constructor(public accountService: AccountService) {
    this.approvedApi = environment.apiUrl+/api/;
    // console.log('JwtInterceptor shouldAddCred ' + this.approvedApi);
   }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(!this.shouldAddCred(request)){
      return next.handle(request);
    }
    let requestWithCred = this.updateHeders(request);

    return next.handle(requestWithCred).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          // !request.url.includes('auth/signin') &&
          error.status === 401
        ) {
          console.log('JwtInterceptor got 401');
          if(this.accountService.userType == UserType.PunchClockUser){
            this.accountService.refresh(this.accountService.tokenValue)
            .subscribe()
          }else{
            this.accountService.logout();
          }
        }
        return throwError(error.error);
      })
    );
  }

  updateHeders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const user = this.accountService.tokenValue;
    const isLoggedIn = user && user.token;

    if (isLoggedIn) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user.token}`
        }
      });
    } else {
      console.log('JwtInterceptor did not set heders');
    }
    return request;
  }

  shouldAddCred(request: HttpRequest<unknown>): boolean {
    let result = request.url.startsWith(this.approvedApi);
    // console.log('JwtInterceptor shouldAddCred ' + result + ' ' + request.url);
    return result;
  }
}
