import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { AccountService } from 'src/app/_services/account.service';
import { CompanyService } from 'src/app/_services/company.service';
import { Company } from 'src/app/_models/company';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit, OnChanges {

  companys: Company[] = [];
  companyId = 0;
  show = false;

  @Input() preSelected = 0;
  @Input() disabled = false;

  @Output() selectedCompanyEvent = new EventEmitter<number>();

  constructor(private companyService: CompanyService,
    private accountService: AccountService) { }

  ngOnInit(): void {
    if (!this.accountService.isSysadmin) {
      this.companyId = this.accountService.companyId;
    } else {
      this.companyService.getCompanys()
        .subscribe(x => this.companys = x);
        this.show = true;
    }
    if(this.preSelected){
      this.companyId = this.preSelected;
    }
    this.selected();
  }

  ngOnChanges():void{
    this.companyId = this.preSelected;
  }

  selected(){
    this.selectedCompanyEvent.emit(this.companyId);
  }

}
