import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/_services/account.service';
import { MessageService } from 'src/app/_services/message.service';
import { Invoice, Retailor } from 'src/app/_models/invoice';
import { PriceType } from 'src/app/_models/price';
import { InvoiceService } from '../_services/invoice.service';
import { ReInvoiceService } from '../_services/re-invoice.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-re-invoice-editor',
  templateUrl: './re-invoice-editor.component.html',
  styleUrls: ['./re-invoice-editor.component.scss'],
})
export class ReInvoiceEditorComponent implements OnInit {
  invoice: Invoice = {} as Invoice;
  editing = false;
  id: number = 0;
  retailors = Retailor;
  priceType = PriceType;

  constructor(
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    private reinvoiceService: ReInvoiceService,
    public accountService: AccountService,
    private location: Location,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
    this.getInvoice();
  }

  getInvoice(): void {
    this.reinvoiceService.getReInvoice(this.id).subscribe((x) => {
      this.invoice = x;
      console.log(x);
    });
  }

  downloade(credit: boolean): void {
    this.reinvoiceService
      .downloadFile(this.id, credit)
      .subscribe((x) => this.invoiceService.downloadFileLocaly(x));
  }

  back() {
    this.location.back();
    // this.router.navigate('../invoices', {relativeTo:this.route});
  }
}
