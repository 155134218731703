<div class="invoice-page-container">
  <div>
    <div class="btn-header">
      <h1>Faktura {{ invoice.customerInvoiceNr }}</h1>
      <div>
        <button mat-raised-button (click)="back()">Tillbaka</button>
        <button *ngIf="accountService.isSysadmin" mat-raised-button color="warn" (click)="delet()">
          Ta bort
        </button>
        <button *ngIf="accountService.isSysadmin" mat-raised-button color="accent" (click)="proces()">
          Bearbeta
        </button>

        <button *ngIf="!createReInvoiceBool" mat-raised-button color="primary" (click)="prepForReinvoice()">
          Redigera
        </button>
        <button *ngIf="createReInvoiceBool" mat-raised-button class="green-button" (click)="createReInvoice()">
          Skapa underlag
        </button>
        <button *ngIf="createReInvoiceBool" mat-raised-button color="warn" (click)="abort()">
          Avbryt
        </button>
      </div>
    </div>
    <app-invoice-explainer></app-invoice-explainer>
    <div *ngIf="createReInvoiceBool">
      <div>Genomsnittlig rabatt {{ avgDiscount | number : "1.0-0" }}%</div>
      <div>
        <input (change)="applyGenneralDisount(genaralDiscount.value)" matInput #genaralDiscount placeholder="0-100%"
          type="number" min="0" max="100" />
      </div>
      <div>
        <button *ngIf="!isAllChekd" mat-raised-button (click)="chekAll(true)">
          Bocka i alla
        </button>
        <button *ngIf="isAllChekd" mat-raised-button (click)="chekAll(false)">
          Bocka ur alla
        </button>
      </div>
    </div>
    <div *ngIf="invoice.invoiceId">
      <mat-card appearance="outlined">
        <ng-container [ngTemplateOutlet]="invoiceFacts" [ngTemplateOutletContext]="{ element: invoice }">
        </ng-container>
        <div>
          <ng-container [ngTemplateOutlet]="tabelhedder"> </ng-container>
          <div *ngFor="let order of invoice.orders">
            <ng-container [ngTemplateOutlet]="orderHedder" [ngTemplateOutletContext]="{ order: order }">
            </ng-container>
          </div>
          <ng-container [ngTemplateOutlet]="invoiceAppliedRows"> </ng-container>
        </div>
        <ng-container [ngTemplateOutlet]="fotter"> </ng-container>
      </mat-card>
      <app-re-invoice-list-per-invoice [invoiceId]="id"></app-re-invoice-list-per-invoice>
    </div>
  </div>
  <app-invoice-viewer [invoiceId]="id"></app-invoice-viewer>
</div>

<ng-template #invoiceFacts let-element="invoice">
  <div class="invoice-header-container">
    <div>Faktura nr</div>
    <div>Fakturadatum</div>
    <div>Återförsäljare</div>
    <div>Inläst</div>
    <div>Status</div>
    <div>
      {{ invoice.invoiceNr }}
    </div>
    <div>
      {{ invoice.invoiceDate | date }}
    </div>
    <div>
      {{ invoice.retailor | enumToDisplay : retailors }}
    </div>
    <div>
      {{ invoice.readTime | fromUtcDate : "YYYY-MM-dd HH:mm:ss" }}
    </div>
    <div>
      <span *ngIf="invoice.invoiceStatus == 1">Inläst</span>
      <span *ngIf="invoice.invoiceStatus == 4">Väntar på bearbeting</span>
      <span *ngIf="invoice.invoiceStatus == 2">Färdig</span>
    </div>
  </div>
</ng-template>

<ng-template #fotter>
  <div class="invoice-fotter-container text-center">
    <div>Totalt momspliktigt</div>
    <div>Ej momspliktigt</div>
    <div>Moms</div>
    <div>Total</div>
    <div>
      {{ invoice.total | currency }}
    </div>
    <div>
      {{ invoice.totalInvoiceRows | currency }}
    </div>
    <div>
      {{ invoice.total * 0.25 | currency }}
    </div>
    <div>
      {{ invoice.total * 1.25 + invoice.totalInvoiceRows | currency }}
    </div>
    <div>
      {{ invoice.newTotal | currency }}
    </div>
    <div>
      {{ invoice.newTotalInvoiceRows | currency }}
    </div>
    <div>
      {{ invoice.newTotal * 0.25 | currency }}
    </div>
    <div>
      {{ invoice.newTotal * 1.25 + invoice.newTotalInvoiceRows | currency }}
    </div>
    <div>Marginal kr</div>
    <div>Marginal %</div>
    <div></div>
    <div></div>
    <div>{{ invoice.newTotal - invoice.total | currency }}</div>
    <div>
      {{ (1 - invoice.total / invoice.newTotal) * 100 | number : "1.0-0" }}%
    </div>
    <div></div>
    <div></div>
  </div>
</ng-template>

<ng-template #tabelhedder>
  <div class="tabel-header">
    <div class="invoice-firstrow-container">
      <div>Art.nr</div>
      <div>Benämning</div>
    </div>
    <div class="invoice-secondrow-container" [ngClass]="{ 'invoice-secondrow-container-edit': createReInvoiceBool }">
      <div *ngIf="createReInvoiceBool" class="text-start">Välj</div>
      <div></div>
      <div>Hyrestid</div>
      <div>Antal Enhet</div>
      <div>
        Netto
        <br />
        á-pris
      </div>
      <div>Ny á-pris</div>
      <div>Rabatt %</div>
      <div *ngIf="createReInvoiceBool">
        Kund <br />
        Rabatt %
      </div>
      <div>Belopp</div>
      <div>Nytt belopp</div>
      <div>Diff</div>
    </div>
  </div>
</ng-template>

<ng-template #invoiceAppliedRows>
  <div *ngIf="invoice.invoiceAppliedRows.length > 0" class="hedder-bacground">
    Faktura rader
  </div>
  <div *ngFor="let invoiceAppliedRow of invoice.invoiceAppliedRows">
    <div class="invoice-firstrow-container brake-word">
      <div>
        {{ invoiceAppliedRow.productName }}
      </div>
    </div>
    <div class="invoice-secondrow-container brake-word"
      [ngClass]="{ 'invoice-secondrow-container-edit': createReInvoiceBool }">
      <div *ngIf="createReInvoiceBool" class="text-start">
        <mat-checkbox class="example-margin" (change)="areAllChekd()"
          [(ngModel)]="invoiceAppliedRow.isCheckBoxed"></mat-checkbox>
      </div>
      <div></div>
      <div>
        {{ invoiceAppliedRow.amount }}
        {{ invoiceAppliedRow.priceType | enumToDisplay : priceType }}
      </div>
      <div>
        {{ invoiceAppliedRow.aPrice | currency }}
      </div>
      <div></div>
      <div>
        <div *ngIf="invoiceAppliedRow.rowAppliedDiscount">
          {{ invoiceAppliedRow.rowAppliedDiscount * 100 }}%
        </div>
      </div>
      <div *ngIf="createReInvoiceBool">
        <input (change)="recalc()" matInput [(ngModel)]="invoiceAppliedRow.customerDiscount" placeholder="0-100%"
          type="number" min="0" max="100" />
      </div>
      <div>
        {{ invoiceAppliedRow.totalReadFromInvoice | currency }}
      </div>
      <div>
        {{ invoiceAppliedRow.newTotal | currency }}
      </div>
      <div>
        {{
        invoiceAppliedRow.newTotal - invoiceAppliedRow.totalReadFromInvoice
        | currency
        }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #orderHedder let-order="order">
  <div class="hedder-bacground">
    <div>Order nr: {{ order.orderNr }}</div>
  </div>
  <div *ngFor="let orderRow of order.rows">
    <div *ngIf="orderRow.orderRowId" class="invoice-firstrow-container brake-word">
      <div>
        {{ orderRow.articleNo }}
      </div>
      <div>
        {{ orderRow.productName }}
      </div>
    </div>
    <div *ngIf="!orderRow.orderRowId" class="invoice-firstrow-container brake-word">
      <div class="dense-1">
        <mat-form-field appearance="fill">
          <mat-label>Art.nr</mat-label>
          <input matInput [(ngModel)]="orderRow.articleNo" />
        </mat-form-field>
      </div>
      <app-product-selector [label]="'Produkt namn'" [retailorId]="invoice.retailor"
        (SelectedProdEvent)="selectProduct(orderRow, $event)"></app-product-selector>
    </div>
    <div *ngFor="let orderSubRow of orderRow.subRows">
      <ng-container [ngTemplateOutlet]="orderSubRowRow" [ngTemplateOutletContext]="{
          orderSubRow: orderSubRow,
          orderRow: orderRow,
          order: order
        }">
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #orderSubRowRow let-orderSubRow="orderSubRow" let-order="order" let-orderRow="orderRow">
  <div class="invoice-secondrow-container brake-word"
    [ngClass]="{ 'invoice-secondrow-container-edit': createReInvoiceBool }">
    <div *ngIf="createReInvoiceBool" style="align-items: center" class="text-start d-flex">
      <mat-checkbox class="example-margin" (change)="areAllChekd()" [(ngModel)]="orderSubRow.isCheckBoxed">
      </mat-checkbox>
      <button mat-icon-button (click)="addRow(order, orderRow)" color="primary" aria-label="Add row">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div (click)="toggelPriceChangeDirection(orderSubRow)">
      <app-compare-price-icon [compareProduct_Id]="orderSubRow.compareProduct_Id"></app-compare-price-icon>
      <app-price-change-indecator
        [priceChangeDirection]="orderSubRow.priceChangeDirection"></app-price-change-indecator>
    </div>
    <div>
      <div *ngIf="orderSubRow.isRental">
        <span *ngIf="!createReInvoiceBool">{{ orderSubRow.rentalTime }}
          {{ orderSubRow.rentalType | enumToDisplay : priceType }}</span>
        <input *ngIf="createReInvoiceBool" (change)="recalc()" matInput class="narrow"
          [(ngModel)]="orderSubRow.rentalTime" type="number" [placeholder]="'Hyrestid'" />
        <app-rental-type-selector *ngIf="createReInvoiceBool && !orderSubRow.orderSubRowId"
          (rentalTypeChange)="orderSubRow.rentalType = $event"></app-rental-type-selector>
      </div>
    </div>
    <div>
      <span *ngIf="!createReInvoiceBool">{{ orderSubRow.amount }}
        {{
        orderSubRow.customerPrice.priceType | enumToDisplay : priceType
        }}</span>
      <input *ngIf="createReInvoiceBool" (change)="recalc()" matInput class="narrow" [(ngModel)]="orderSubRow.amount"
        type="number" [placeholder]="'Antal'" />
      <app-price-type-selector *ngIf="createReInvoiceBool && !orderSubRow.orderSubRowId"
        (priceTTypeChange)="orderSubRow.customerPrice.priceType = $event"></app-price-type-selector>
    </div>
    <div [ngClass]="{ 'no-trust': !orderSubRow.customerPrice.trustedPrice }">
      <span *ngIf="!(createReInvoiceBool && !orderSubRow.orderSubRowId)">{{
        orderSubRow.customerPrice.priceOfTransaction | currency
        }}</span>
      <input *ngIf="createReInvoiceBool && !orderSubRow.orderSubRowId" (change)="recalc()" matInput class="narrow"
        [(ngModel)]="orderSubRow.customerPrice.aPrice" type="number" [placeholder]="'á-pris'" />
    </div>
    <div>
      <div *ngIf="!createReInvoiceBool" style="margin-left: auto">
        <span *ngIf="orderSubRow.hasNewPrice">
          {{ orderSubRow.newPrice.aPrice | currency }}
        </span>
        <span *ngIf="!orderSubRow.hasNewPrice" class="no-price-in-db">
          {{ orderSubRow.customerPrice.aPrice | currency }}
        </span>
      </div>
      <input *ngIf="createReInvoiceBool && orderSubRow.orderSubRowId" (change)="recalc()" matInput class="narrow"
        [(ngModel)]="orderSubRow.newPrice.aPrice" type="number" />
    </div>
    <div>
      <div *ngIf="orderSubRow.percentageDifferenceOfPrice != undefined">
        {{ orderSubRow.percentageDifferenceOfPrice * 100 | number : "1.0-0" }}%
      </div>
    </div>
    <div *ngIf="createReInvoiceBool">
      <input (change)="recalc()" matInput [(ngModel)]="orderSubRow.customerDiscount" placeholder="0-100%" type="number"
        min="0" max="100" />
    </div>
    <div>
      {{ orderSubRow.totalReadFromInvoice | currency }}
    </div>
    <div>
      {{ orderSubRow.newTotal | currency }}
    </div>
    <div>
      {{ orderSubRow.newTotal - orderSubRow.totalReadFromInvoice | currency }}
    </div>
  </div>
  <div *ngIf="orderSubRow.showPriceDevelopment">
    <app-compare-price [show]="true" [orderSubRowId]="orderSubRow.orderSubRowId"
      [productId]="orderSubRow.customerPrice.product_Id"
      [priceType]="orderSubRow.customerPrice.priceType"></app-compare-price>
  </div>
</ng-template>