import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { PromptUpdateService } from './_services/sw/prompt-update.service';
import { LogUpdateService } from './_services/sw/log-update.service';
import { HandleUnrecoverableStateService } from './_services/sw/handle-unrecoverable-state.service';
import { CheckForUpdateService } from './_services/sw/check-for-update.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import packageInfo from '../../package.json';
import { AccountService } from './_services/account.service';
import { MessageService } from './_services/message.service';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public version: string = packageInfo.version; //version;
  public environmentName = environment.name;

  constructor(
    private router: Router,
    public accountService: AccountService,
    public messageService: MessageService,
    public CheckForUpdateService: CheckForUpdateService,
    public HandleUnrecoverableStateService: HandleUnrecoverableStateService,
    public LogUpdateService: LogUpdateService,
    public PromptUpdateService: PromptUpdateService) {

  }

  ngOnInit(): void {
    this.accountService.loadToken().then(x => {
      // const isLogdIn = this.accountService.isLogdIn();
      // if(!isLogdIn){
      //   this.router.navigate(['/']);
      // }
    });
  }

  appInsigtsSetup() {
    var angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: '3c8975bd-ea44-4560-b9f2-30ee9a475812',
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router }
        }
      }
    });
    appInsights.loadAppInsights();
  }


}
