<div class="btn-header">
  <h1>
    Fakturaunderlag {{ invoice.customerInvoiceNr }}-{{
      invoice.customerReInvoiceNr
    }}
  </h1>
  <div>
    <button mat-raised-button color="primary" (click)="downloade(false)">
      Ladda ner
    </button>
    <button mat-raised-button color="primary" (click)="downloade(true)">
      Ladda ner Kredit
    </button>
    <button mat-raised-button (click)="back()">Tillbaka</button>
  </div>
</div>

<div *ngIf="invoice.invoiceId">
  <mat-card appearance="outlined">
    <ng-container
      [ngTemplateOutlet]="invoiceFacts"
      [ngTemplateOutletContext]="{ element: invoice }"
    >
    </ng-container>
    <div>
      <ng-container [ngTemplateOutlet]="tabelhedder"> </ng-container>
      <div *ngFor="let order of invoice.orders">
        <ng-container
          [ngTemplateOutlet]="orderHedder"
          [ngTemplateOutletContext]="{ order: order }"
        >
        </ng-container>
      </div>
      <ng-container [ngTemplateOutlet]="invoiceAppliedRows"> </ng-container>
    </div>
    <ng-container [ngTemplateOutlet]="fotter"> </ng-container>
  </mat-card>
</div>

<ng-template #invoiceFacts let-element="invoice">
  <div class="invoice-header-container">
    <div>Faktura nr</div>
    <div>Fakturadatum</div>
    <div>Återförsäljare</div>
    <div>Inläst</div>
    <div>
      {{ invoice.invoiceNr }}
    </div>
    <div>
      {{ invoice.invoiceDate | date }}
    </div>
    <div>
      {{ invoice.retailor | enumToDisplay : retailors }}
    </div>
    <div>
      {{ invoice.readTime | fromUtcDate : "YYYY-MM-dd HH:mm:ss" }}
    </div>
  </div>
</ng-template>

<ng-template #fotter>
  <div class="invoice-fotter-container text-center">
    <div>Totalt momspliktigt</div>
    <div>Ej momspliktigt</div>
    <div>Moms</div>
    <div>Total</div>
    <div>
      {{ invoice.total | currency }}
    </div>
    <div>
      {{ invoice.totalInvoiceRows | currency }}
    </div>
    <div>
      {{ invoice.total * 0.25 | currency }}
    </div>
    <div>
      {{ invoice.total * 1.25 + invoice.totalInvoiceRows | currency }}
    </div>
    <div>
      {{ invoice.newTotal | currency }}
    </div>
    <div>
      {{ invoice.newTotalInvoiceRows | currency }}
    </div>
    <div>
      {{ invoice.newTotal * 0.25 | currency }}
    </div>
    <div>
      {{ invoice.newTotal * 1.25 + invoice.newTotalInvoiceRows | currency }}
    </div>
    <div></div>
    <div></div>
    <div></div>
    <div>
      {{ invoice.newTotal - invoice.total | currency }}
    </div>
  </div>
</ng-template>

<ng-template #tabelhedder>
  <div class="tabel-header">
    <div class="invoice-firstrow-container">
      <div>Art.nr</div>
      <div>Benämning</div>
    </div>
    <div class="invoice-secondrow-container">
      <div>Hyrestid</div>
      <div>Antal Enhet</div>
      <div>á-pris</div>
      <div>á-pris Ny</div>
      <div>Utrymme %</div>
      <div>Ursprungligt Belopp</div>
      <div>Kund Rabatt %</div>
      <div>Nytt belopp</div>
      <div>Diff</div>
    </div>
  </div>
</ng-template>

<ng-template #invoiceAppliedRows>
  <div *ngIf="invoice.invoiceAppliedRows.length > 0" class="hedder-bacground">
    Faktura rader
  </div>
  <div *ngFor="let invoiceAppliedRow of invoice.invoiceAppliedRows">
    <div class="invoice-firstrow-container">
      <div>
        {{ invoiceAppliedRow.productName }}
      </div>
    </div>
    <div class="invoice-secondrow-container">
      <div></div>
      <div>
        {{ invoiceAppliedRow.amount }}
        {{ invoiceAppliedRow.priceType | enumToDisplay : priceType }}
      </div>
      <div>
        {{ invoiceAppliedRow.aPrice | currency }}
      </div>
      <div></div>
      <div>
        <div *ngIf="invoiceAppliedRow.rowAppliedDiscount">
          {{ invoiceAppliedRow.rowAppliedDiscount * 100 }}%
        </div>
      </div>
      <div>
        {{ invoiceAppliedRow.totalReadFromInvoice | currency }}
      </div>
      <div>
        <div *ngIf="invoiceAppliedRow.customerDiscount != undefined">
          {{ -(invoiceAppliedRow.customerDiscount * 100) | number : "1.0-0" }}%
        </div>
      </div>
      <div>
        {{ invoiceAppliedRow.newTotal | currency }}
      </div>
      <div>
        {{
          invoiceAppliedRow.newTotal - invoiceAppliedRow.totalReadFromInvoice
            | currency
        }}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #orderHedder let-order="order">
  <div class="hedder-bacground">
    <div>Order nr: {{ order.orderNr }}</div>
  </div>
  <div *ngFor="let orderRow of order.rows">
    <div class="invoice-firstrow-container brake-word">
      <div>
        {{ orderRow.articleNo }}
      </div>
      <div>
        {{ orderRow.productName }}
      </div>
    </div>
    <div *ngFor="let orderSubRow of orderRow.subRows">
      <ng-container
        [ngTemplateOutlet]="orderSubRowRow"
        [ngTemplateOutletContext]="{ orderSubRow: orderSubRow }"
      >
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #orderSubRowRow let-orderSubRow="orderSubRow">
  <div class="invoice-secondrow-container brake-word">
    <div>
      <div *ngIf="orderSubRow.isRental">
        {{ orderSubRow.rentalTime }}
        {{ orderSubRow.rentalType | enumToDisplay : priceType }}
      </div>
    </div>
    <div>
      {{ orderSubRow.amount }}
      {{ orderSubRow.customerPrice.priceType | enumToDisplay : priceType }}
    </div>
    <div>
      {{ orderSubRow.customerPrice.priceOfTransaction | currency }}
    </div>
    <div class="d-flex">
      <div style="margin-left: auto">
        <span *ngIf="orderSubRow.hasNewPrice">
          {{ orderSubRow.newPrice.aPrice | currency }}
        </span>
        <span *ngIf="!orderSubRow.hasNewPrice">
          {{ orderSubRow.customerPrice.aPrice | currency }}
        </span>
      </div>
    </div>
    <div>
      <div *ngIf="orderSubRow.percentageDifferenceOfPrice != undefined">
        {{ orderSubRow.percentageDifferenceOfPrice * 100 | number : "1.0-0" }}%
      </div>
    </div>
    <div>
      {{ orderSubRow.totalReadFromInvoice | currency }}
    </div>
    <div>
      <div *ngIf="orderSubRow.customerDiscount != undefined">
        {{ -(orderSubRow.customerDiscount * 100) | number : "1.0-0" }}%
      </div>
    </div>
    <div>
      {{ orderSubRow.newTotal | currency }}
    </div>
    <div>
      {{ orderSubRow.newTotal - orderSubRow.totalReadFromInvoice | currency }}
    </div>
  </div>
</ng-template>
