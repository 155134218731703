import { InvoiceAppliedRow, Order, OrderRow } from './../../_models/invoice';
import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/_services/account.service';
import { MessageService } from 'src/app/_services/message.service';
import { Invoice, OrderSubRow, Retailor } from 'src/app/_models/invoice';
import { Price, PriceType } from 'src/app/_models/price';
import { InvoiceService } from '../_services/invoice.service';
import { Location } from '@angular/common';
import { ReInvoiceService } from '../_services/re-invoice.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ReInvoiceWithoutInvoice } from 'src/app/_models/reInvoiceWithoutInvoice';
import { ReInvoiceListPerInvoiceComponent } from '../re-invoice-list-per-invoice/re-invoice-list-per-invoice.component';
import { Product } from 'src/app/_models/product';
import { PriceService } from 'src/app/products/_services/price.service';

@Component({
  selector: 'app-invoice-editor',
  templateUrl: './invoice-editor.component.html',
  styleUrls: ['./invoice-editor.component.scss'],
})
export class InvoiceEditorComponent implements OnInit {
  invoice: Invoice = {} as Invoice;
  editing = false;
  id: number = 0;
  retailors = Retailor;
  priceType = PriceType;
  createReInvoiceBool = false;
  avgDiscount = 0;
  imageList: string[] = [];
  imageUrl: SafeUrl | null = null;
  isAllChekd = false;

  @ViewChild(ReInvoiceListPerInvoiceComponent)
  private reInvoiceListPerInvoiceComponent!: ReInvoiceListPerInvoiceComponent;

  constructor(
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    public accountService: AccountService,
    public messageService: MessageService,
    public priceService:PriceService,
    private router: Router,
    private location: Location,
    private sanitizer: DomSanitizer,
    private reinvoiceService: ReInvoiceService
  ) {
    this.id = Number(this.route.snapshot.paramMap.get('id'));
  }

  ngOnInit(): void {
    this.getInvoice();
  }

  getInvoice(): void {
    this.invoiceService.getInvoice(this.id).subscribe((x) => {
      this.invoice = x;
      this.avgDiscount = (1 - x.total / x.newTotal) * 100;
    });
  }

  downloade(): void {
    this.invoiceService
      .downloadFile(this.id)
      .subscribe((x) => this.invoiceService.downloadFileLocaly(x));
  }

  delet() {
    this.messageService.deletConfirmation().subscribe((confirmed) => {
      if (confirmed) {
        this.deletFile();
      } else {
        console.log(false);
      }
    });
  }

  deletFile() {
    this.messageService.setSpinner(true);
    this.invoiceService.deleteInvoice(this.id).subscribe(
      (x) => {
        this.messageService.endSpinnerWithMessage('Faktura bortagen');
        this.back();
      },
      (error) => {
        this.messageService.endSpinnerWithMessage(error);
      }
    );
  }

  back() {
    this.location.back();
    // this.router.navigate('../invoices', {relativeTo:this.route});
  }

  toggelPriceChangeDirection(orderSubRow: OrderSubRow) {
    if (orderSubRow.showPriceDevelopment) {
      orderSubRow.showPriceDevelopment = false;
      return;
    }
    orderSubRow.showPriceDevelopment = true;
  }

  proces() {
    this.messageService.setSpinner(true);
    this.invoiceService.proccesInvoice(this.id).subscribe(
      (x) => {
        this.messageService.endSpinnerWithMessage('Bearbetad');
        this.getInvoice();
      },
      (error) => {
        this.messageService.endSpinnerWithMessage(error);
      }
    );
  }

  recalc() {
    this.invoiceService.updateTotalsOnInvoice(this.invoice, false);
  }

  prepForReinvoice() {
    this.createReInvoiceBool = true;
    for (let i1 = 0; i1 < this.invoice.orders.length; i1++) {
      const order = this.invoice.orders[i1];
      for (let i2 = 0; i2 < order.rows.length; i2++) {
        const orderRow = order.rows[i2];
        for (let i3 = 0; i3 < orderRow.subRows.length; i3++) {
          if (orderRow.subRows[i3].newPrice === undefined) {
            orderRow.subRows[i3].newPrice = this.copyPrice(
              orderRow.subRows[i3].customerPrice
            );
          }
        }
      }
    }
  }

  copyPrice(price: Price): Price {
    const p = {} as Price;
    p.aPrice = price.aPrice;
    p.priceType = price.priceType;
    p.dateOfPrice = price.dateOfPrice;
    console.log('copy price');
    return p;
  }

  abort() {
    this.createReInvoiceBool = false;
    this.getInvoice();
    this.chekAll(false);
    this.applyGenneralDisount('');
  }

  applyGenneralDisount(discountStr: string) {
    let discount = Number(discountStr);
    for (let i1 = 0; i1 < this.invoice.orders.length; i1++) {
      const order = this.invoice.orders[i1];
      for (let i2 = 0; i2 < order.rows.length; i2++) {
        const orderRow = order.rows[i2];
        for (let i3 = 0; i3 < orderRow.subRows.length; i3++) {
          orderRow.subRows[i3].customerDiscount = discount;
        }
      }
    }
    for (
      let index = 0;
      index < this.invoice.invoiceAppliedRows.length;
      index++
    ) {
      this.invoice.invoiceAppliedRows[index].customerDiscount = discount;
    }
    this.recalc();
  }

  chekAll(chek: boolean) {
    for (let i1 = 0; i1 < this.invoice.orders.length; i1++) {
      const order = this.invoice.orders[i1];
      for (let i2 = 0; i2 < order.rows.length; i2++) {
        const orderRow = order.rows[i2];
        for (let i3 = 0; i3 < orderRow.subRows.length; i3++) {
          orderRow.subRows[i3].isCheckBoxed = chek;
        }
      }
    }
    for (
      let index = 0;
      index < this.invoice.invoiceAppliedRows.length;
      index++
    ) {
      this.invoice.invoiceAppliedRows[index].isCheckBoxed = chek;
    }
    this.isAllChekd = chek;
  }

  areAllChekd() {
    let allCHek = true;
    for (let i1 = 0; i1 < this.invoice.orders.length; i1++) {
      const order = this.invoice.orders[i1];
      for (let i2 = 0; i2 < order.rows.length; i2++) {
        const orderRow = order.rows[i2];
        for (let i3 = 0; i3 < orderRow.subRows.length; i3++) {
          if (!orderRow.subRows[i3].isCheckBoxed) {
            allCHek = false;
          }
        }
      }
    }
    for (
      let index = 0;
      index < this.invoice.invoiceAppliedRows.length;
      index++
    ) {
      if (!this.invoice.invoiceAppliedRows[index].isCheckBoxed) {
        allCHek = false;
      }
    }
    this.isAllChekd = allCHek;
  }

  createReInvoice() {
    this.messageService.setSpinner(true);
    let reInvoice = {} as ReInvoiceWithoutInvoice;
    reInvoice.invoiceId = this.invoice.invoiceId;

    reInvoice.orders = [];
    reInvoice.invoiceAppliedRows = [];

    for (let i1 = 0; i1 < this.invoice.orders.length; i1++) {
      const order: Order = structuredClone(this.invoice.orders[i1]);
      const orderRows = order.rows;
      order.rows = [];
      for (let i2 = 0; i2 < orderRows.length; i2++) {
        const orderRow: OrderRow = structuredClone(orderRows[i2]);
        delete orderRow.orderRowId;
        this.invoiceService.ifNullThenUndefind(orderRow, 'articleNo');
        this.invoiceService.ifNullThenUndefind(orderRow, 'orderRowNumber');
        const subRows = orderRow.subRows;
        orderRow.subRows = [];
        for (let i3 = 0; i3 < subRows.length; i3++) {
          if (subRows[i3].isCheckBoxed) {
            const subRow: OrderSubRow = structuredClone(subRows[i3]);
            this.invoiceService.ifNullThenUndefind(subRow, 'customerDiscount');

            orderRow.subRows.push(subRow);
          }
        }
        if (orderRow.subRows.length > 0) order.rows.push(orderRow);
      }
      if (order.rows.length > 0) reInvoice.orders.push(order);
    }

    for (
      let index = 0;
      index < this.invoice.invoiceAppliedRows.length;
      index++
    ) {
      if (this.invoice.invoiceAppliedRows[index].isCheckBoxed) {
        const invoiceRow: InvoiceAppliedRow = structuredClone(
          this.invoice.invoiceAppliedRows[index]
        );
        delete invoiceRow.invoice_Id;
        delete invoiceRow.invoiceAppliedRowId;
        this.invoiceService.ifNullThenUndefind(invoiceRow, 'customerDiscount');
        reInvoice.invoiceAppliedRows.push(invoiceRow);
      }
    }

    console.log(reInvoice);
    this.reinvoiceService.createReInvoice(reInvoice).subscribe(
      (x) => {
        this.messageService.endSpinnerWithMessage('Skapad');
        this.abort();
        this.reInvoiceListPerInvoiceComponent.getReInvoices();
      },
      (error) => {
        this.messageService.endSpinnerWithMessage(error);
      }
    );
  }

  addRow(order: Order, orderRow: OrderRow): void {
    let index = order.rows.findIndex((x) => x == orderRow);
    console.log(index);
    const newRow = {} as OrderRow;
    newRow.subRows = [];
    const subrow = {} as OrderSubRow;
    subrow.hasNewPrice = false;
    subrow.showPriceDevelopment = false;
    subrow.isRental = true;
    subrow.isCheckBoxed = true;

    subrow.totalReadFromInvoice = 0;

    subrow.customerPrice = {} as Price;
    subrow.customerPrice.trustedPrice = true;

    newRow.subRows.push(subrow);
    order.rows.splice(index + 1, 0, newRow);
  }

  selectProduct(orderRow: OrderRow, prod:Product):void{
    orderRow.articleNo = prod.articleNo;
    orderRow.productName = prod.productName;
    this.priceService
    .getLatestPriceByProductId(prod.productId)
    .subscribe((x) => {
      if (x) {
        let subRow = orderRow.subRows[0];
        subRow.customerPrice.priceType = x.priceType;
        subRow.customerPrice.aPrice = x.aPrice;
        subRow.customerPrice.product_Id = prod.productId;

        if (x.discountOfAPrice) {
          subRow.customerPrice.discountOfAPrice = -x.discountOfAPrice * 100;
        }

        subRow.showPriceDevelopment = true;
        this.recalc();
      }
    });
  }
}
