<select [(ngModel)]="rentalType" [disabled]="disabled" (change)="selected()">
  <option [ngValue]="0" disabled selected>Välj hyrtyp</option>
  <option [ngValue]="33">
    Vecka
  </option>
  <option [ngValue]="34">
    Dag
  </option>
  <option [ngValue]="35">
    Timme
  </option>
  <option [ngValue]="36">
    Minut
  </option>
</select>
