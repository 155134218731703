import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GoogelMapsService } from '../_services/googel-maps.service';
import { Location } from '../../project/_models/location';

@Component({
  selector: 'app-search-location-map',
  templateUrl: './search-location-map.component.html',
  styleUrls: ['./search-location-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchLocationMapComponent implements OnInit {

  @Input() disabled = false;
  @Input() pos: Location | undefined;
  @Output() posChange = new EventEmitter<Location>();

  center: google.maps.LatLngLiteral;
  zoom = 4;
  markerPosition: google.maps.LatLngLiteral | undefined;
  markerOptions: google.maps.MarkerOptions;

  constructor(public googelMapsService: GoogelMapsService) {
    this.center = this.googelMapsService.getCeneterMap(60, 15);
    this.markerOptions = this.googelMapsService.getMarkerOptions(false);
  }

  ngOnInit(): void {
    if (this.pos !== undefined && this.pos.latitude !== undefined && this.pos.longitude !== undefined) {
      const pos = {} as google.maps.LatLngLiteral;
      pos.lat = this.pos.latitude;
      pos.lng = this.pos.longitude;
      console.log(pos);
      this.setPos(pos);
    }
  }

  addMarker(event: google.maps.MapMouseEvent) {
    if (this.disabled) return;
    this.markerPosition = this.googelMapsService.getMarkerPosition(event);
  }

  setPos(position: google.maps.LatLngLiteral) {
    this.markerPosition = position;
    this.center = position;
    this.zoom = 15;
    this.send();
  }

  send() {
    if (this.markerPosition === undefined) return;
    if (this.pos === undefined) this.pos = {} as Location;
    this.pos.latitude = this.markerPosition?.lat;
    this.pos.longitude = this.markerPosition?.lng;

    this.posChange.emit(this.pos);
  }

}
