import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nav-button-menu',
  templateUrl: './nav-button-menu.component.html',
  styleUrls: ['./nav-button-menu.component.scss']
})
export class NavButtonMenuComponent {
  @Input() buttonName: string | undefined;
  @Input() menuOptions: NavOption[] | undefined;

  constructor() { }



}

export interface NavOption{
  name: string;
  route: string;
}
