<div class="btn-header">
  <h1>{{hedder}}</h1>
  <div>
    <ng-container *ngTemplateOutlet="extraButtons || empty"></ng-container>

    <ng-container *ngTemplateOutlet="
      headers || defaultHeaderTemplate;
    "></ng-container>
  </div>
</div>

<ng-template #defaultHeaderTemplate let-data>
  <button *ngIf="showBack" mat-raised-button (click)="back()">Tillbaka</button>
  <button *ngIf="editing" mat-raised-button color="primary" (click)="save()">Spara</button>
  <button *ngIf="editing" mat-raised-button (click)="cancel()">Avbryt</button>
  <button *ngIf="!editing" mat-raised-button (click)="editClickEvent()">Redigera</button>
  <button *ngIf="!editing" mat-raised-button color="warn" (click)="delete()">Ta bort</button>
</ng-template>

<ng-template #empty></ng-template>
