import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MessageService } from '../message.service';

@Injectable({
  providedIn: 'root'
})
export class HandleUnrecoverableStateService {
  constructor(
    updates: SwUpdate,
    messageService: MessageService) {
    updates.unrecoverable.subscribe(event => {
      messageService.alertDialog(
        'An error occurred that we cannot recover from:\n' +
        event.reason +
        '\n\nPlease reload the page.');
    });
  }
}
