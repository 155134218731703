<div class="image-container">
  <img *ngIf="imageUrl" [src]="imageUrl" class="responsive-img" />
  <mat-progress-bar *ngIf="loding" mode="indeterminate"></mat-progress-bar>
  <div style="display: flex">
    <div *ngFor="let imageLink of imageList; let i = index" st>
      <button
        mat-raised-button
        [disabled]="imageLink === currentImage"
        (click)="getImage(imageLink)"
      >
        {{ i + 1 }}
      </button>
    </div>
  </div>
</div>
