import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-simpel-input-dialog',
  templateUrl: './simpel-input-dialog.component.html',
  styleUrls: ['./simpel-input-dialog.component.scss']
})
export class SimpelInputDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SimpelInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SimpelInputDialogData,
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

export interface SimpelInputDialogData {
  qestion: string;
  answer: string;
  hedder: string;
  hint: string;
}