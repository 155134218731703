import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateReInvoiceComponent } from './create-re-invoice/create-re-invoice.component';
import { FailedUploadesComponent } from './failed-uploades/failed-uploades.component';
import { InvoiceEditorComponent } from './invoice-editor/invoice-editor.component';
import { InvoicesComponent } from './invoices/invoices.component';

import { LayoutComponent } from './layout/layout.component';
import { ManualUploadComponent } from './manual-upload/manual-upload.component';
import { ReInvoiceEditorComponent } from './re-invoice-editor/re-invoice-editor.component';
import { ReInvoicesComponent } from './re-invoices/re-invoices.component';
import { UploadeInvoiceComponent } from './uploade-invoice/uploade-invoice.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: 'invoice-editor/:id', component: InvoiceEditorComponent },
      { path: 'invoices', component: InvoicesComponent },
      { path: 're-invoice-editor/:id', component: ReInvoiceEditorComponent },
      {
        path: 'manual-upload/:companyId/:retailorId',
        component: ManualUploadComponent,
      },
      { path: 'uploade', component: UploadeInvoiceComponent },
      { path: 'underlag', component: CreateReInvoiceComponent },
      { path: 're-invoices', component: ReInvoicesComponent },
      { path: 'error', component: FailedUploadesComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InvoiceManagerRoutingModule {}
