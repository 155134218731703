import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-button-hedder',
  templateUrl: './button-hedder.component.html',
  styleUrls: ['./button-hedder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonHedderComponent implements OnInit {

  @Input() editing: boolean = false;
  @Input() showBack: boolean = false;
  @Input() hedder = '';

  @Output() saveClick = new EventEmitter();
  @Output() cancelClick = new EventEmitter();
  @Output() editClick = new EventEmitter();
  @Output() deleteClick = new EventEmitter();
  @Output() backClick = new EventEmitter();

  @ContentChild('headers') headers: TemplateRef<any> | undefined;
  @ContentChild('extraButtons') extraButtons: TemplateRef<any> | undefined;

  constructor() { }

  ngOnInit(): void {
  }

  save(): void {
    this.saveClick.emit();
  }

  cancel(): void {
    this.cancelClick.emit();
  }

  editClickEvent(): void {
    this.editClick.emit();
  }

  delete(): void {
    this.deleteClick.emit();
  }

  back(): void {
    this.backClick.emit();
  }

}
