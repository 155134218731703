import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {

  //   transform(data: Object) : string[]{
  //     const keys = Object.keys(data);
  //     return keys.slice(keys.length / 2);
  // }

  transform(value:any): any[] {
    return Object.keys(value).filter(e => !isNaN(+e)).map(o => { return { index: +o, name: value[o] } });
  }

}
