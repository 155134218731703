<div class="dense-1">
  <mat-form-field appearance="fill">
    <mat-label>{{ label }}</mat-label>
    <input matInput #artNo minlength="1" (keyup)="searchTextChanged.next(artNo.value)" [(ngModel)]="textValue"
      [matAutocomplete]="auto" [disabled]="disabled" />
    <mat-autocomplete #auto="matAutocomplete" [panelWidth]="500">
      <mat-option *ngIf="posibolProduct.length == 0 && !searching && lastSerchString.length > 0">
        Inga resultat
      </mat-option>
      <mat-option *ngFor="let option of posibolProduct" [value]="option.articleNo" (click)="selectOption(option)">
        <span [innerHTML]="displayText(option) | highlight : lastSerchString"></span>
        &nbsp;
      </mat-option>
    </mat-autocomplete>
    <mat-progress-bar *ngIf="searching" mode="query"></mat-progress-bar>
  </mat-form-field>
</div>