import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-simpel-map',
  templateUrl: './simpel-map.component.html',
  styleUrls: ['./simpel-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SimpelMapComponent {
  private currentApiStatus: BehaviorSubject<boolean>;
  apiLoaded: Observable<boolean>;
  apiKey = 'AIzaSyA-jA31TDCIXTBh_MJ3193g08VyN8r40yA';
  url = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}`;
  display: google.maps.LatLngLiteral | undefined;

  @ViewChild('map') mapElement: any;
  map: google.maps.Map;

  constructor(private httpClient: HttpClient) {
    this.currentApiStatus = new BehaviorSubject(false);
    this.apiLoaded = this.currentApiStatus.asObservable();
    console.log('SimpelMapComponent ' + this.url);
    this.map = {} as google.maps.Map;
    this.httpClient.jsonp(this.url, 'callback')
      .pipe(
        map(() => true),
        catchError((e) => {
          console.log(e);
          return of(false);
        }),
      ).subscribe((value) => {
        console.log('SimpelMapComponent ' + value);
        this.currentApiStatus.next(value);
      }
      );
  }

  center: google.maps.LatLngLiteral = { lat: 60, lng: 15 };
  zoom = 4;
  markerOptions: google.maps.MarkerOptions = { draggable: false };
  markerPosition: google.maps.LatLngLiteral | undefined;

  addMarker(event: google.maps.MapMouseEvent) {
    if (!event.latLng) return;
    this.markerPosition = event.latLng.toJSON();
    console.log(this.markerPosition);
  }
}
