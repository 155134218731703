import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Invoice } from 'src/app/_models/invoice';
import { ReInvoiceWithoutInvoice } from 'src/app/_models/reInvoiceWithoutInvoice';
import { TableWrapper } from 'src/app/_models/tableWrapper';
import { BaseService } from 'src/app/_services/base.service';
import { MessageService } from 'src/app/_services/message.service';

@Injectable({
  providedIn: 'root'
})
export class ReInvoiceService extends BaseService {

  constructor(
    private http: HttpClient,
    public messageService: MessageService,
    public dialog: MatDialog
  ) {
    super(messageService, dialog);
  }

  public createReInvoice(reInvoiceWithoutInvoice: ReInvoiceWithoutInvoice): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/api/ReInvoice/CreateReInvoice/`, reInvoiceWithoutInvoice )
      .pipe(
        catchError(this.handleError<boolean>('createReInvoice'))
      );
  }

  public createReInvoiceWithoutInvoice(reInvoiceWithoutInvoice: ReInvoiceWithoutInvoice): Observable<number> {
    return this.http.post<number>(`${environment.apiUrl}/api/ReInvoice/CreateReInvoiceWithoutInvoice/`, reInvoiceWithoutInvoice)
      .pipe(
        catchError(this.handleError<number>('createReInvoice'))
      );
  }

  public getAllReInvoiceByInvoice(invoiceId: number): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(`${environment.apiUrl}/api/ReInvoice/GetByInvoices/` + invoiceId)
      .pipe(
        catchError(this.handleError<Invoice[]>('getAllReInvoiceByInvoice'))
      );
  }

  public getReInvoice(reInvoiceId: number): Observable<Invoice> {
    return this.http.get<Invoice>(`${environment.apiUrl}/api/ReInvoice/Get/` + reInvoiceId)
      .pipe(
        catchError(this.handleError<Invoice>('getReInvoice'))
      );
  }

  public downloadFile(id: number, credit: boolean): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/ReInvoice/DownloadFile/${id}/${credit}`, { observe: 'response', responseType: 'blob' }) // , { responseType: 'blob' as 'json' }, { responseType: 'blob'}
      .pipe(
        map((res) => {
          console.log(res);
          const fileNameStr = res.headers.get('content-disposition')?.split('\'') || [];
          let data = {
            file: new Blob([res.body || '{}'], { type: res.headers.get('Content-Type') || '{}' }),
            filename: fileNameStr[fileNameStr.length - 1]
          }
          return data;
        }),
        catchError(this.handleError('DownloadFile'))
      );
  }

  public findinvoices(companyID: number, retailor: number = 0, filter: string, sortOrder: string, sortCol: string, pageNumber: number, pageSize: number): Observable<TableWrapper> {
    let params = new HttpParams()
      .set('companyId', companyID.toString())
      .set('filter', filter)
      .set('sortOrder', sortOrder)
      .set('sortCol', sortCol)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    if (retailor) {
      params = params.set('retailor', retailor.toString());
    }
    return this.http.get<TableWrapper>(`${environment.apiUrl}/api/ReInvoice/FindReInvoices`, {
      params: params
    }).pipe(
      catchError(this.handleError<TableWrapper>('findinvoices'))
    );
  }
}
