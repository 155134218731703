<div class="header">
  <h1 mat-dialog-title>Välj Datum och tid</h1>
</div>
<div mat-dialog-content>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Välj datum</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="date" (dateInput)="addEvent($event)">
      <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="w-100">
      <mat-label><b>Välj Tid </b></mat-label>
      <input type="time" matInput [(ngModel)]="time">
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="cancel()">Avbryt</button>
  <button mat-raised-button (click)="confirm()">Ok</button>
</div>
