<div class="btn-header">
  <h1>UnderLag</h1>
  <button mat-raised-button color="primary" routerLink="/invoice/underlag">
    Lägg till Underlag
  </button>
</div>
<div class="d-flex">
  <app-company-selector
    (selectedCompanyEvent)="companyId = $event; load()"
  ></app-company-selector>
  <app-supplier-selector
    (selectedRetailorEvent)="selectedRetailor = $event; load()"
  ></app-supplier-selector>

  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="searchTextChanged.next(artNo.value)"
      placeholder="Sök"
      #artNo
    />
    <button
      *ngIf="artNo.value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="artNo.value = ''; searchTextChanged.next(artNo.value)"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>

<div class="spinner-container" *ngIf="dataSource.loading$ | async">
  <mat-spinner></mat-spinner>
</div>
<div class="table-container mat-elevation-z8">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    matSortActive="invoiceDate"
    matSortDirection="desc"
  >
    <ng-container matColumnDef="customerReInvoiceNr">
      <th mat-header-cell *matHeaderCellDef>Nr</th>
      <td mat-cell *matCellDef="let element">
        <a
          routerLink="/invoice/re-invoice-editor/{{ element.reInvoicingId }}"
          >{{ element.customerReInvoiceNr }}</a
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="invoiceNr">
      <th mat-header-cell *matHeaderCellDef>Faktura nr</th>
      <td mat-cell *matCellDef="let element">
        {{ element.invoiceNr }}
      </td>
    </ng-container>

    <ng-container matColumnDef="invoiceDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Fakturdatum</th>
      <td mat-cell *matCellDef="let element">
        {{ element.invoiceDate | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="retailor">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Återförsäljare</th>
      <td mat-cell *matCellDef="let element">
        {{ element.retailor | enumToDisplay : retailors }}
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Totalbelop</th>
      <td mat-cell *matCellDef="let element">{{ element.total | currency }}</td>
    </ng-container>

    <ng-container matColumnDef="readTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Inläst</th>
      <td mat-cell *matCellDef="let element">
        {{ element.readTime | fromUtcDate : "YYYY-MM-dd HH:mm:ss" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="company_Id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Företag</th>
      <td mat-cell *matCellDef="let element">{{ element.company_Id }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    class="mat-elevation-z8"
    [pageSizeOptions]="[10, 20, 100]"
    [pageSize]="20"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
