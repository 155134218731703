<app-spinner [show]="(messageService.loading$ | async)||false"></app-spinner>

<div class="main-content">
  <app-nav-bar class="navbar"></app-nav-bar>
  <div class="app-container">
    <router-outlet></router-outlet>
  </div>
  <footer>
    <span>
      {{version}}
    </span>
    <span *ngIf="environmentName  === 'development'">
      {{environmentName }}
    </span>
  </footer>
</div>
