<select [(ngModel)]="priceType" [disabled]="disabled" (change)="selected()">
  <option [ngValue]="0" disabled selected>Välj pristyp</option>
  <option [ngValue]="10">
    St
  </option>
  <option [ngValue]="1">
    M
  </option>
  <option [ngValue]="2">
    MM
  </option>
  <option [ngValue]="3">
    Ton
  </option>
  <option [ngValue]="4">
    Kg
  </option>
  <option [ngValue]="5">
    hg
  </option>
  <option [ngValue]="6">
    g
  </option>
  <option [ngValue]="7">
    L
  </option>
  <option [ngValue]="8">
    M2
  </option>
  <option [ngValue]="9">
    M3
  </option>
  <option [ngValue]="33">
    Vecka
  </option>
  <option [ngValue]="34">
    Dag
  </option>
  <option [ngValue]="35">
    Timme
  </option>
  <option [ngValue]="36">
    Minut
  </option>
  <option [ngValue]="11">
    HundraSt
  </option>
  <option [ngValue]="12">
    Förpakning
  </option>
  <option [ngValue]="13">
    Ask
  </option>
  <option [ngValue]="14">
    Paket
  </option>
  <option [ngValue]="15">
    Kartong
  </option>
  <option [ngValue]="16">
    Säck
  </option>
  <option [ngValue]="17">
    Skiva
  </option>
  <option [ngValue]="16">
    Säck
  </option>
  <option [ngValue]="18">
    Set
  </option>
  <option [ngValue]="19">
    Bunt
  </option>
  <option [ngValue]="20">
    Påse
  </option>
  <option [ngValue]="21">
    Par
  </option>
  <option [ngValue]="22">
    Låda
  </option>
  <option [ngValue]="23">
    Rulle
  </option>
  <option [ngValue]="24">
    Burk
  </option>
  <option [ngValue]="25">
    Flaska
  </option>
  <option [ngValue]="26">
    PAT
  </option>
  <option [ngValue]="27">
    Pall
  </option>
  <option [ngValue]="28">
    Hink
  </option>
  <option [ngValue]="29">
    Tub
  </option>
  <option [ngValue]="30">
    STS
  </option>
  <option [ngValue]="31">
    Ark
  </option>
  <option [ngValue]="32">
    Bal
  </option>
  <option [ngValue]="37">
    Fat
  </option>
  <option [ngValue]="39">
    Rör
  </option>
  <option [ngValue]="40">
    PRT
  </option>
  <option [ngValue]="41">
    SB
  </option>
</select>
