import { Product } from '../../_models/product';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseService } from '../../_services/base.service';
import { MessageService } from '../../_services/message.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Price, PriceType } from '../../_models/price';

@Injectable({
  providedIn: 'root'
})
export class PriceService extends BaseService {

  constructor(
    private http: HttpClient,
    public messageService: MessageService,
    public dialog: MatDialog
  ) {
    super(messageService, dialog);
  }

  public getPriceById(priceId: number): Observable<Price> {
    return this.http.get<Price>(`${environment.apiUrl}/api/Price/GetPriceById?priceId=${priceId}`)
      .pipe(
        catchError(this.handleError<Price> ('GetPriceById'))
      );
  }

  public getAllByProductId(productId: number, priceType: PriceType): Observable<Price[]> {
    return this.http.get<Price[]>(`${environment.apiUrl}/api/Price/getAllByProductId?productId=${productId}&&priceType=${priceType}`)
      .pipe(
        catchError(this.handleError<Price[]> ('getAllByProductId'))
      );
  }

  public getLatestPriceByProductId(productId: number): Observable<Price> {
    return this.http.get<Price>(`${environment.apiUrl}/api/Price/GetLatestPriceByProductId?productId=${productId}`)
      .pipe(
        catchError(this.handleError<Price> ('GetLatestPriceByProductId'))
      );
  }

  public getLatestPriceByProductIdAndPriceType(productId: number, priceType: PriceType): Observable<Price> {
    return this.http.get<Price>(`${environment.apiUrl}/api/Price/GetLatestPriceByProductIdAndPriceType?productId=${productId}&&priceType=${priceType}`)
      .pipe(
        catchError(this.handleError<Price> ('GetLatestPriceByProductIdAndPriceType'))
      );
  }

  public getLatestCompetorProductPriceByProductId(productId: number, priceType: PriceType): Observable<Product[]> {
    return this.http.get<Product[]>(`${environment.apiUrl}/api/Price/GetLatestCompetorProductPriceByProductId?productId=${productId}&&priceType=${priceType}`)
      .pipe(
        catchError(this.handleError<Product[]>('GetLatestCompetorProductPriceByProductId'))
      );
  }

  public addNewCustomerPrice(price: Price): Observable<Price> {
    return this.http.post<Price>(`${environment.apiUrl}/api/Price/AddNewCustomerPrice`, price)
      .pipe(
        catchError(this.handleError<Price>('AddNewCustomerPrice'))
      );
  }

  recalcPrice(price: Price) {
    if (price.aPrice) {
      if (price.discountOfAPrice) {
        price.priceOfTransaction = price.aPrice * (1 - (price.discountOfAPrice / 100));
      } else {
        price.priceOfTransaction = price.aPrice;
      }
    } else {
      price.priceOfTransaction = 0;
    }
  }
}
