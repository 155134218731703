<h1>Fritt underlag</h1>
<div>
  <app-company-selector
    (selectedCompanyEvent)="reInvoiceWithoutInvoice.company_Id = $event"
  ></app-company-selector>
  <app-supplier-selector
    (selectedRetailorEvent)="reInvoiceWithoutInvoice.retailor = $event"
  ></app-supplier-selector>
</div>
<h2>Orders</h2>
<div *ngFor="let order of invoice.orders">
  <mat-card appearance="outlined">
    <div>
      <!-- <mat-form-field appearance="fill">
        <mat-label>Order nummer</mat-label>
        <input matInput minlength="4" [(ngModel)]="order.orderNr">
      </mat-form-field> -->
      <button mat-raised-button color="warn" (click)="deleteOrder(order)">
        Ta bort order
      </button>
    </div>
    <mat-divider></mat-divider>
    <div *ngFor="let row of order.rows" class="order-container">
      <div class="order-row-hedder">
        <mat-form-field appearance="fill">
          <mat-label>Artikel nummer</mat-label>
          <input matInput minlength="4" [(ngModel)]="row.articleNo" required />
        </mat-form-field>
        <app-product-selector
          (SelectedProdEvent)="selectOption($event, row)"
          (textValueEvent)="row.productName = $event"
          [retailorId]="reInvoiceWithoutInvoice.retailor"
        ></app-product-selector>
        <button
          mat-raised-button
          color="warn"
          class="fl-r"
          (click)="deleteOrderRow(row)"
        >
          Ta bort rad
        </button>
      </div>
      <!-- <button mat-mini-fab color="primary" class="tab-right" (click)="addOrderSubRow(row)">
        <mat-icon>add</mat-icon>
      </button> -->
      <div *ngFor="let subrow of row.subRows">
        <div class="cells">
          <div>Antal</div>
          <div>Pris Enhet</div>
          <div>Hyrestid</div>
          <div>Hyr Enhet</div>
          <div>Brutto á-pris</div>
          <div>Rabatt %</div>
          <div>Belopp</div>

          <input
            type="number"
            class="narrow-Input"
            (change)="recalcPrice(subrow.customerPrice)"
            [(ngModel)]="subrow.amount"
          />

          <select
            class="narrow-Input"
            [(ngModel)]="subrow.customerPrice.priceType"
            (ngModelChange)="getPriceByType(subrow)"
          >
            <option
              *ngFor="let x of priceTypes | enumToArray"
              [ngValue]="x.index"
            >
              {{ x.name }}
            </option>
          </select>

          <input
            type="number"
            class="narrow-Input"
            (change)="recalc()"
            [(ngModel)]="subrow.rentalTime"
          />

          <app-rental-type-selector
            [preSelected]="subrow.rentalType"
            (rentalTypeChange)="subrow.rentalType = $event"
          >
          </app-rental-type-selector>

          <input
            type="number"
            class="narrow-Input"
            (change)="recalcPrice(subrow.customerPrice)"
            [(ngModel)]="subrow.customerPrice.aPrice"
          />
          <input
            type="number"
            class="narrow-Input"
            (change)="recalcPrice(subrow.customerPrice)"
            [(ngModel)]="subrow.customerPrice.discountOfAPrice"
          />
          <div style="background: #fff">
            {{ subrow.totalReadFromInvoice | currency }}
          </div>
        </div>
        <app-compare-price
          [show]="subrow.showPriceDevelopment"
          [productId]="subrow.customerPrice.product_Id"
          [priceType]="subrow.customerPrice.priceType"
          [ComapreDate]="invoice.invoiceDate"
          [CompanyId]="reInvoiceWithoutInvoice.company_Id"
        >
        </app-compare-price>
      </div>
      <mat-divider></mat-divider>
    </div>
    <div class="order-row-add-container">
      <button
        mat-raised-button
        color="primary"
        class=""
        (click)="addOrderRow(order)"
      >
        Lägg till rad
      </button>
    </div>
  </mat-card>
</div>
<div class="order-row-add-container">
  <button mat-raised-button color="primary" (click)="addOrder()">
    Lägg till order
  </button>
</div>
<h2>InvoiceAppliedRows</h2>
<mat-card appearance="outlined">
  <div *ngFor="let row of invoice.invoiceAppliedRows">
    <div>
      <mat-form-field appearance="fill">
        <mat-label>Artikel nummer</mat-label>
        <input matInput minlength="4" [(ngModel)]="row.articleNo" />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Produkt namn</mat-label>
        <input matInput minlength="4" [(ngModel)]="row.productName" required />
      </mat-form-field>
      <button
        mat-raised-button
        color="warn"
        class="fl-r"
        (click)="deleteInvoiceAppliedRow(row)"
      >
        Ta bort rad
      </button>
    </div>
    <div class="cells">
      <div>Antal</div>
      <div>Brutto á-pris</div>
      <div>Pris Enhet</div>
      <div></div>
      <div></div>
      <div></div>
      <div>Belopp</div>
      <input (change)="recalc()" type="number" [(ngModel)]="row.amount" />
      <input (change)="recalc()" type="number" [(ngModel)]="row.aPrice" />
      <select [(ngModel)]="row.priceType" required>
        <option>None</option>
        <option *ngFor="let x of priceTypes | enumToArray" [ngValue]="x.index">
          {{ x.name }}
        </option>
      </select>
      <!-- <input minlength="4" [(ngModel)]="row.productName">
        <input minlength="4" [(ngModel)]="row.articleNo"> -->
      <div></div>
      <div></div>
      <div></div>
      <div style="background: #fff">
        {{ row.totalReadFromInvoice | currency }}
      </div>
    </div>
  </div>
  <div class="order-row-add-container">
    <button mat-raised-button color="primary" (click)="addInvoiceRow()">
      Lägg till Ej momspliktigt rad
    </button>
  </div>
</mat-card>
<div class="fl-r">
  {{ invoice.total | currency }} Total
  <button mat-raised-button color="primary" (click)="create()">Spara</button>
</div>
