import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Company } from '../_models/company';
import { BaseService } from './base.service';
import { MessageService } from './message.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends BaseService {

  constructor(
    private http: HttpClient,
    public messageService: MessageService,
    public dialog: MatDialog) {
    super(messageService, dialog);
  }

  public createCompany(company: Company): Observable<Company> {
    return this.http.post<Company>(`${environment.apiUrl}/api/Company/createCompany`, company)
      .pipe(
        catchError(this.handleError<Company>('createCompany'))
      );
  }

  public deleatCompany(id: number): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/api/Company/deleatCompany/` + id)
      .pipe(
        catchError(this.handleError<boolean>('deleatCompany'))
      );
  }

  public getCompany(id: number): Observable<Company> {
    return this.http.get<Company>(`${environment.apiUrl}/api/Company/GetCompany/` + id)
      .pipe(
        catchError(this.handleError<Company>('getCompany'))
      );
  }

  public getCompanys(): Observable<Company[]> {
    return this.http.get<Company[]>(`${environment.apiUrl}/api/Company/GetCompanys`)
      .pipe(
        catchError(this.handleError<Company[]>('getCompanys'))
      );
  }

  public updateCompany(company: Company): Observable<Company> {
    return this.http.put<Company>(`${environment.apiUrl}/api/Company/updateCompany`, company)
      .pipe(
        catchError(this.handleError<Company>('updateCompany'))
      );
  }

  public updateInvoiceEmail(id: number, invoiceMail: string, invoiceMailPassword: string): Observable<void> {
    let params = new HttpParams();
    params = params.set('invoiceMail', invoiceMail);
    params = params.set('invoiceMailPassword', invoiceMailPassword);
    params = params.set('id', id);
    return this.http.put<void>(`${environment.apiUrl}/api/Company/UpdateInvoiceEmail?` + params.toString(), id)
      .pipe(
        catchError(this.handleError<void>('UpdateInvoiceEmail'))
      );
  }

  public getInvoiceEmail(id: number): Observable<string> {
    return this.http.get(`${environment.apiUrl}/api/Company/getInvoiceEmail/${id}`, { responseType: 'text' })
      .pipe(
        catchError(this.handleError<string>('getInvoiceEmail'))
      );
  }

  public getPlaceholder(list: Company[], s: string): Company {
    list = this.sortByName(list);

    const placeholder = {} as Company;
    placeholder.name = s;
    placeholder.companyId = 0;
    list.unshift(placeholder);
    return placeholder;
  }

  public sortByName(list: Company[]): Company[] {
    list = list.sort((a, b) => {
      return this.intlCollator.compare(a.name.toUpperCase(), b.name.toUpperCase());
    });
    return list;
  }
}
