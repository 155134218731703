import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor() { }

  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getIntervalInMs(period: number): Observable<number> {
    return interval(period);
  }

  getIntervalInS(period: number): Observable<number> {
    return interval(1000 * period);
  }

  getIntervalInM(period: number): Observable<number> {
    return interval(1000 * 60 * period);
  }

  getIntervalInH(period: number): Observable<number> {
    return interval(1000 * 60 * 60 * period);
  }

  public timeToNextHourInMs(currentTimestampMs: number): number {
    const timestampSeconds = currentTimestampMs / 1000;
    const numberOfSecondsIntoTheCurrentHour = timestampSeconds % 3600;
    const numberOfSecondsToTheNextHour = 3600 - numberOfSecondsIntoTheCurrentHour;
    // console.log('timeToNextHourInMs: ' + numberOfSecondsToTheNextHour);
    return numberOfSecondsToTheNextHour * 1000;
  };

  public timeToNextMinuteInMs(currentTimestampMs: number): number {
    const timestampSeconds = currentTimestampMs / 1000;
    const numberOfSecondsIntoTheCurrentMinute = timestampSeconds % 60;
    const numberOfSecondsToTheNextMinute = 60 - numberOfSecondsIntoTheCurrentMinute;
    // console.log('timeToNextMinuteInMs: ' + numberOfSecondsToTheNextMinute);
    return numberOfSecondsToTheNextMinute * 1000;
  };

  public timeToFiveNextMinuteInMs(currentTimestampMs: number): number {
    const timestampSeconds = currentTimestampMs / 1000;
    const numberOfSecondsIntoTheCurrentMinute = timestampSeconds % 300;
    const numberOfSecondsToTheNextMinute = 300 - numberOfSecondsIntoTheCurrentMinute;
    // console.log('timeToNextMinuteInMs: ' + numberOfSecondsToTheNextMinute);
    return numberOfSecondsToTheNextMinute * 1000;
  };
}
