import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GoogelMapsService } from './../_services/googel-maps.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Product } from 'src/app/_models/product';

@Component({
  selector: 'app-search-addres-fieald',
  templateUrl: './search-addres-fieald.component.html',
  styleUrls: ['./search-addres-fieald.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SearchAddresFiealdComponent {
  @Input() label = 'Adress';
  textValue = '';
  @Input() disabled = false;

  @Output() SelectedPosistionEvent = new EventEmitter<google.maps.LatLngLiteral>();
  @Output() textValueEvent = new EventEmitter<string>();

  posibolAddreses: BehaviorSubject<google.maps.GeocoderResult[]>;
  filteredOptions!: Observable<google.maps.GeocoderResult[]>;
  searchTextChanged = new Subject<string>();
  lastSerchString = '';
  searching = false;

  constructor(private googelMapsService: GoogelMapsService) {
    this.posibolAddreses = new BehaviorSubject<google.maps.GeocoderResult[]>([]);
    this.filteredOptions = this.posibolAddreses.asObservable();
   }

  ngOnInit(): void {
    this.searchTextChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((x) => x.length > 0)
      )
      .subscribe((val) => {
        this.search(val);
      });
  }

  search(val: string) {
    this.textValueEvent.emit(val);
    if (val === this.lastSerchString) return;
    this.lastSerchString = val;


    this.searching = true;
    this.googelMapsService
      .getGeoCodeAddress(val)
      .subscribe((x) => {
        this.posibolAddreses.next(x);

        console.log(x);
        this.searching = false;
      });
  }

  displayText(prod: google.maps.GeocoderResult): string {
    return prod.formatted_address;
  }

  selectOption(prod: google.maps.GeocoderResult) {
    this.textValue = prod.formatted_address;
    const pos = (prod.geometry?.location?.toJSON() as google.maps.LatLngLiteral);
    this.SelectedPosistionEvent.emit(pos);
  }
}
