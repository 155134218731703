import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseService } from 'src/app/_services/base.service';
import { MessageService } from 'src/app/_services/message.service';

@Injectable({
  providedIn: 'root'
})
export class GoogelMapsService extends BaseService {

  private currentApiStatus: BehaviorSubject<boolean>;
  apiLoaded$: Observable<boolean>;
  apiKey = 'AIzaSyA-jA31TDCIXTBh_MJ3193g08VyN8r40yA';
  url = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}`;

  // geocoder: google.maps.Geocoder;

  constructor(
    private http: HttpClient,
    public messageService: MessageService,
    public dialog: MatDialog) {
    super(messageService, dialog);

    this.currentApiStatus = new BehaviorSubject(false);
    this.apiLoaded$ = this.currentApiStatus.asObservable();
    this.loadApi();
    // this.geocoder = new google.maps.Geocoder();
  }

  loadApi(): void {
    if (this.currentApiStatus.value) return;
    this.http.jsonp(this.url, 'callback')
      .pipe(
        map(() => true),
        catchError((e) => {
          console.log(e);
          return of(false);
        }),
      ).subscribe((value) => {
        console.log('GoogelMapsService ' + value);
        this.currentApiStatus.next(value);
      }
      );
  }

  getCeneterMap(lat: number, lang: number): google.maps.LatLngLiteral {
    return { lat: lat, lng: lang };
  }

  getMarkerPosition(event: google.maps.MapMouseEvent): google.maps.LatLngLiteral | undefined {
    if (!event.latLng) return;
    return event.latLng.toJSON();
  }

  getMarkerOptions(draggable: boolean): google.maps.MarkerOptions {
    return { draggable: draggable };
  }

  getGeoCodeAddress(locationstr: string): Observable<google.maps.GeocoderResult[]> {
    const geocoder = new google.maps.Geocoder();
    var req = this.getGeocoderRequest(locationstr);

    return new Observable<google.maps.GeocoderResult[]>((observer) => {
      geocoder.geocode(req, (results, status) => {
        if (status === 'OK') {
          console.log(results);
          if (results) {
            observer.next(results);
          } else {
            observer.next([]);
          }
        } else {
          observer.error(status);
        }
        observer.complete();
      });
    });
  }

  getGeocoderRequest(address: string): google.maps.GeocoderRequest {
    const r: google.maps.GeocoderRequest = {
      address: address,
      componentRestrictions: { country: 'se', },
      language: 'sv',
    };

    return r;
  }

  getAutocomplete(locationstr: string): Observable<google.maps.places.AutocompletePrediction[]> {
    const auto = new google.maps.places.AutocompleteService();

    const req: google.maps.places.AutocompletionRequest = {
      input: locationstr,
      componentRestrictions: { country: 'se', },
      language: 'sv',

    };

    return new Observable<google.maps.places.AutocompletePrediction[]>((observer) => {
      auto.getPlacePredictions(req, (results, status) => {
        if (status === 'OK') {
          console.log(results);
          if (results) {
            observer.next(results);
          } else {
            observer.next([]);
          }
        } else {
          observer.error(status);
        }
        observer.complete();
      });
    });
  }
}
