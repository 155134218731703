import { PriceType } from 'src/app/_models/price';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { OrderSubRow } from 'src/app/_models/invoice';
import { PriceDevelopment, PriceDevelopmentWraper } from 'src/app/_models/priceDevelopment';
import { BaseService } from 'src/app/_services/base.service';
import { MessageService } from 'src/app/_services/message.service';


@Injectable({
  providedIn: 'root'
})
export class PriceDevelopmentService extends BaseService {

  constructor(
    private http: HttpClient,
    public messageService: MessageService,
    public dialog: MatDialog
  ) {
    super(messageService, dialog);
  }

  public getPriceDevelopment(orderSubRowId: number): Observable<PriceDevelopment> {
    return this.http.get<PriceDevelopment>(`${environment.apiUrl}/api/PriceDevelopment/Get/${orderSubRowId}`)
      .pipe(
        catchError(this.handleError<PriceDevelopment>('getPriceDevelopment'))
      );
  }

  public getPriceDevelopmentByCostomer(companyid: number): Observable<PriceDevelopment[]> {
    return this.http.get<PriceDevelopment[]>(`${environment.apiUrl}/api/PriceDevelopment/GetByCustomer/${companyid}`)
      .pipe(
        catchError(this.handleError<PriceDevelopment[]>('getPriceDevelopmentByCostomer'))
      );
  }

  public getExtendedPriceDevelopment(orderSubRowId: number): Observable<PriceDevelopmentWraper> {
    return this.http.get<PriceDevelopmentWraper>(`${environment.apiUrl}/api/PriceDevelopment/GetExtended/${orderSubRowId}`)
      .pipe(
        catchError(this.handleError<PriceDevelopmentWraper>('getPriceDevelopment'))
      );
  }

  public getExtendedPriceDevelopmentByProduct(productid: number, companyId: number, invoiceDate: Date, priceType: PriceType): Observable<PriceDevelopmentWraper> {
    let params = new HttpParams();
    params = params.set('productid', productid);
    params = params.set('companyId', companyId);
    params = params.set('invoiceDate', invoiceDate.toISOString());
    params = params.set('priceType', priceType);
    return this.http.get<PriceDevelopmentWraper>(`${environment.apiUrl}/api/PriceDevelopment/GetExtendedByProduct?` + params.toString())
      .pipe(
        catchError(this.handleError<PriceDevelopmentWraper>('getPriceDevelopment'))
      );
  }

  public getCompedetiveSubRow(orderSubRowId: number): Observable<OrderSubRow> {
    return this.http.get<OrderSubRow>(`${environment.apiUrl}/api/PriceDevelopment/GetCompedetiveSubRow/${orderSubRowId}`)
      .pipe(
        catchError(this.handleError<OrderSubRow>('GetCompedetiveSubRow'))
      );
  }
}
