import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  ignoreElements,
  map,
  startWith,
} from 'rxjs/operators';
import { PriceService } from 'src/app/products/_services/price.service';
import { ProductService } from 'src/app/products/_services/product.service';
import { MessageService } from 'src/app/_services/message.service';
import {
  Invoice,
  InvoiceAppliedRow,
  Order,
  OrderRow,
  OrderSubRow,
} from 'src/app/_models/invoice';
import { Price, PriceType } from 'src/app/_models/price';
import { Product } from 'src/app/_models/product';
import { InvoiceService } from '../_services/invoice.service';

@Component({
  selector: 'app-manual-upload',
  templateUrl: './manual-upload.component.html',
  styleUrls: ['./manual-upload.component.scss'],
})
export class ManualUploadComponent implements OnInit {
  invoiceNr: string = '';
  date = new UntypedFormControl(new Date());
  step = 1;
  companyId = -1;
  retailorId = -1;
  invoice!: Invoice;
  priceTypes = PriceType;

  posibolProduct: Product[] = [];
  filteredOptions!: Observable<Product[]>;
  searchTextChanged = new Subject<string>();
  lastSerchString = '';

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    public messageService: MessageService,
    private productService: ProductService,
    private priceService: PriceService,
    private router: Router
  ) {}

  ngOnInit() {
    this.companyId = Number(this.route.snapshot.paramMap.get('companyId'));
    this.retailorId = Number(this.route.snapshot.paramMap.get('retailorId'));
    this.searchTextChanged
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter((x) => x.length > 0)
      )
      .subscribe((val) => {
        if (val !== this.lastSerchString) {
          this.lastSerchString = val;
          this.productService
            .findPossibleMatchesOfProduct(val, this.retailorId)
            .subscribe((x) => {
              this.posibolProduct = x;
            });
        }
      });
  }

  checkInvoiceDetails() {
    if (this.checkInvoiceDate()) {
      this.invoiceService
        .doInvoiceExist(this.companyId, this.retailorId, this.invoiceNr)
        .subscribe(
          (x) => {
            if (x) {
              this.messageService.alertDialog('Faktura existerar redan');
            } else {
              this.createInvoice();
              this.step = 2;
            }
          },
          (error) => {
            this.messageService.endSpinnerWithMessage(error);
          }
        );
    } else {
      this.messageService.alertDialog('Inte giltligt datum');
    }
  }

  createInvoice() {
    this.invoice = {} as Invoice;
    this.invoice.company_Id = this.companyId;
    this.invoice.retailor = this.retailorId;
    this.invoice.invoiceDate = this.date.value;
    this.invoice.invoiceNr = this.invoiceNr;

    this.invoice.orders = [];
    this.invoice.invoiceAppliedRows = [];
    this.addOrder();
    this.recalc();
  }

  checkInvoiceDate(): boolean {
    if (this.date.value) {
      const timeDate = this.date.value.getTime() / 1000.0;
      return true;
    }
    return false;
  }

  addOrder() {
    this.invoiceService.addOrder(this.invoice);
  }

  addOrderRow(order: Order) {
    this.invoiceService.addOrderRow(order);
  }

  addOrderSubRow(orderRow: OrderRow) {
    this.invoiceService.addOrderSubRow(orderRow);
  }

  addInvoiceRow() {
    this.invoiceService.addInvoiceRow(this.invoice);
  }

  deleteOrder(order: Order) {
    this.invoiceService.deleteOrder(this.invoice, order);
  }

  deleteOrderRow(orderRow: OrderRow) {
    this.invoiceService.deleteOrderRow(this.invoice, orderRow);
  }

  deleteOrderSubRow(orderSubRow: OrderSubRow) {
    this.invoiceService.deleteOrderSubRow(this.invoice, orderSubRow);
  }

  deleteInvoiceAppliedRow(invoiceAppliedRow: InvoiceAppliedRow) {
    this.invoiceService.deleteInvoiceAppliedRow(
      this.invoice,
      invoiceAppliedRow
    );
  }

  create() {
    this.validate();
    this.messageService.setSpinner(true);
    console.log(this.invoice);
    this.recalc();
    this.invoiceService.createInvoiceFromModel(this.invoice).subscribe(
      (x) => {
        this.messageService.endSpinnerWithMessage('Skapad');
        this.router.navigateByUrl('invoice/invoice-editor/' + x);
      },
      (error) => {
        this.messageService.endSpinnerWithMessage(error);
      }
    );
  }

  validate() {}

  recalcPrice(price: Price) {
    this.priceService.recalcPrice(price);

    this.recalc();
  }

  recalc() {
    this.invoiceService.updateTotalsOnInvoice(this.invoice, true);
  }

  selectOption(prod: Product, row: OrderRow) {
    row.articleNo = prod.articleNo;
    row.productName = prod.productName;
    this.priceService
      .getLatestPriceByProductId(prod.productId)
      .subscribe((x) => {
        if (x) {
          let subRow = row.subRows[0];
          subRow.customerPrice.priceType = x.priceType;
          subRow.customerPrice.aPrice = x.aPrice;
          subRow.customerPrice.product_Id = prod.productId;

          if (x.discountOfAPrice) {
            subRow.customerPrice.discountOfAPrice = -x.discountOfAPrice * 100;
          }

          subRow.showPriceDevelopment = true;
          this.recalcPrice(subRow.customerPrice);
        }
      });
  }

  getPriceByType(orderSubRow: OrderSubRow): void {
    if (!orderSubRow.customerPrice.product_Id) return;
    if (!orderSubRow.customerPrice.priceType) return;
    this.priceService
      .getLatestPriceByProductIdAndPriceType(
        orderSubRow.customerPrice.product_Id,
        orderSubRow.customerPrice.priceType
      )
      .subscribe((x) => {
        if (x) {
          orderSubRow.customerPrice.aPrice = x.aPrice;
        }
      });
  }
}
