import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Retailor } from 'src/app/_models/invoice';

@Component({
  selector: 'app-supplier-selector',
  templateUrl: './supplier-selector.component.html',
  styleUrls: ['./supplier-selector.component.scss']
})
export class SupplierSelectorComponent implements OnInit {

  retailors = Retailor;
  selectedRetailors: Retailor = -1;

  @Input() preSelected = 0;
  @Input() disabled = false;

  @Output() selectedRetailorEvent = new EventEmitter<Retailor>();
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges():void{
    this.selectedRetailors = this.preSelected;
  }

  selected(){
    this.selectedRetailorEvent.emit(this.selectedRetailors);
  }

}
