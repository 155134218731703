<div class="mat-elevation-z8 top-container">
  <div class="row-container brake-word">
    <div></div>
    <div>Återförsäljare</div>
    <div>Art.nr</div>
    <div>Benämning</div>
    <div>
      Netto
      <br />
      á-pris
    </div>
    <div>Datum</div>
    <div>
      Netto
      <br />
      á-pris
    </div>
    <div>Datum</div>
  </div>

  <div *ngFor="let pd of priceDevelopments" class="row-container brake-word">
    <div>
      <mat-icon style="color: red"> trending_up </mat-icon>
    </div>
    <div>
      {{ pd.retailor | enumToDisplay : retailors }}
    </div>
    <div>
      {{ pd.articleNo }}
    </div>
    <div>
      {{ pd.productName }}
    </div>
    <div>
      {{ pd.previousPriceOfTransaction | currency }}
    </div>
    <div>
      {{ pd.previousDateOfPrice | date }}
    </div>
    <div>
      {{ pd.currentPriceOfTransaction | currency }}
    </div>
    <div>
      {{ pd.currentDateOfPrice | date }}
    </div>
  </div>
</div>
