export interface User {
  userId: number;
  firstName: string;
  lastName: string;
  phoneNr: string;
  email: string;
  password: string;
  company_Id: number;
  companyName: string;
  personNr: string;
  userType: UserType;
  consultingFirm: string;
}

export enum UserType {
  SysAdmin = 1,
  CustomerUser = 2,
  ProductUpdator = 3,
  TimeReportingUser = 4,
  PunchClockUser = 5,
  DeveloperUser = 6
}
