<div class="explanation-invoice-container">
  <app-compare-price-icon [compareProduct_Id]="1"></app-compare-price-icon>
  <div>Prisjämförelse annan leverantör</div>
  <app-price-change-indecator
    [priceChangeDirection]="3"
  ></app-price-change-indecator>
  <div>Artikel oförändrad mot senaste inköp</div>
  <app-price-change-indecator
    [priceChangeDirection]="1"
  ></app-price-change-indecator>
  <div>Prisförändring upp</div>
  <app-price-change-indecator
    [priceChangeDirection]="2"
  ></app-price-change-indecator>
  <div>Prisförändring ner</div>
  <div>
    <span class="no-price-in-db">
      {{ 10 | currency }}
    </span>
  </div>
  <div>
    Artikel finns ej, omkostnader för leverantör, transport, byggpall, emballage
    m.m.
  </div>
  <div>
    <span class="no-trust">
      {{ 10 | currency }}
    </span>
  </div>
  <div>Värdet skall ej litas på</div>
</div>
