import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PriceType } from 'src/app/_models/price';

@Component({
  selector: 'app-rental-type-selector',
  templateUrl: './rental-type-selector.component.html',
  styleUrls: ['./rental-type-selector.component.scss']
})
export class RentalTypeSelectorComponent implements OnInit {
  rentalType = 0;

  @Input() preSelected = 0;
  @Input() disabled = false;

  @Output()  rentalTypeChange = new EventEmitter<number>();

  constructor() {

  }

  ngOnInit(): void {

  }

  ngOnChanges():void{
    this.rentalType = this.preSelected;
  }

  selected() {
    this.rentalTypeChange.emit(this.rentalType);
  }

}
