<h1>Skapa Inköpsunderlag</h1>
<app-company-selector
  (selectedCompanyEvent)="selectedCompanyCompanyId = $event"
></app-company-selector>
<app-supplier-selector
  (selectedRetailorEvent)="selectedRetailors = $event"
></app-supplier-selector>
<div>
  <input
    type="file"
    #file
    placeholder="Choose file"
    style="display: none"
    (change)="uploadeInvoice(file.files)"
  />
  <button
    mat-raised-button
    (click)="file.click()"
    [disabled]="selectedCompanyCompanyId < 0 || selectedRetailors < 0"
  >
    Ladda upp faktura
  </button>
</div>

<div>
  <input
    type="file"
    multiple
    #files
    placeholder="Choose files"
    style="display: none"
    (change)="uploadeInvoices(files.files)"
  />
  <button
    mat-raised-button
    (click)="files.click()"
    [disabled]="selectedCompanyCompanyId < 0 || selectedRetailors < 0"
  >
    Ladda upp fler fakturor
  </button>
</div>
<div>
  <button
    mat-raised-button
    [routerLink]="[
      '../manual-upload',
      selectedCompanyCompanyId,
      selectedRetailors
    ]"
    [disabled]="selectedCompanyCompanyId < 0 || selectedRetailors < 0"
  >
    Skapa manuellt inköpsunderlag
  </button>
</div>
