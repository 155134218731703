<button *ngIf="!edit" mat-icon-button matTooltip="Redigera" class="edit" (click)="editClickEvent()">
    <mat-icon>edit</mat-icon>
  </button>
  <button *ngIf="!edit || isNewProduct" mat-icon-button matTooltip="Ta bort" class="delete" (click)="delete()">
    <mat-icon>delete</mat-icon>
  </button>
  <button *ngIf="edit && !isNewProduct" mat-icon-button matTooltip="Avbryt" class="cancel" (click)="cancel()">
    <mat-icon>cancel</mat-icon>
  </button>
  <button *ngIf="edit" mat-icon-button matTooltip="Spara" class="save" (click)="save()">
    <mat-icon>save</mat-icon>
  </button>
  