import { UserType } from '../_models/user';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, of, EMPTY } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import jwt_decode from "jwt-decode";

import { Token } from '../_models/token';
import { BaseService } from './base.service';
import { MessageService } from './message.service';
import { environment } from 'src/environments/environment';
import { TimeService } from './time.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseService {

  private tokenSubject!: BehaviorSubject<Token>;
  public token!: Observable<Token>;
  public isSysadmin = false;
  public userType: UserType = UserType.CustomerUser;
  public companyId = -1;
  private rerechIsOngoing = false;
  private needToResetPassword = false;

  subscription: Subscription | undefined;

  constructor(
    private router: Router,
    private http: HttpClient,
    public messageService: MessageService,
    public dialog: MatDialog,
    private timeService: TimeService
  ) {
    super(messageService, dialog);
    this.tokenSubject = new BehaviorSubject<Token>(<Token>{});
    this.token = this.tokenSubject.asObservable();
    this.token.subscribe(x => this.setTokenInfo(x.token));

    const source = this.timeService.getIntervalInH(1);
    this.subscription = source.subscribe(async val => this.refresh(this.tokenValue).subscribe());
  }

  loadToken() {
    return new Promise<void>((resolve, reject) => {
      let token = localStorage.getItem('token');
      if (!token || token == 'undefined') {
        resolve();
        return;
      }

      let t = JSON.parse(token);
      this.tokenSubject.next(t);
      if (this.ChekisLoggdIn(t)) {

        resolve();
      } else {
        this.refresh(t).subscribe(x => resolve());
      }
    });

  }

  //Todo add logout time

  public get tokenValue(): Token {
    return this.tokenSubject.value;
  }

  isLogdIn(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/api/User/IsLogdIn`)
      .pipe(
        catchError(this.handleError<boolean>('isLogdIn'))
      );
  }

  login(username: string, password: string): Observable<void> {
    return this.http.post<Token>(`${environment.apiUrl}/api/User/LogIn`, { username, password })
      .pipe(
        catchError(this.handleError<Token>('GetUser')),
        map(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('token', JSON.stringify(user));
          this.tokenSubject.next(user);
        })
      );
  }

  changePassword(newPassword: string) {
    throw new Error('Method not implemented.');
  }

  refresh(token: Token): Observable<void> {
    if (!token || !token.token) {
      console.log('no token');
      // const myObservable = of(false);
      this.logout();
      return of();
    }

    if(this.userType != UserType.PunchClockUser){
      return of();
    }

    if (this.rerechIsOngoing) {
      return EMPTY.pipe(delay(1000));
    } else {
      this.rerechIsOngoing = true;
      return this.http.post<Token>(`${environment.apiUrl}/api/User/refresh`, token)
        .pipe(
          catchError(this.handleError<Token>('GetUser')),
          map(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('token', JSON.stringify(user));
            this.tokenSubject.next(user);
            this.rerechIsOngoing = false;

            if (!this.ChekisLoggdIn(user)) {
              this.logout();
            }
          })
        );
    }
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('token');
    this.tokenSubject.next(<Token>{});
    this.router.navigate(['/account/login']);
  }

  setTokenInfo(tokenStr: string) {
    if (tokenStr) {
      var decoded: any = jwt_decode(tokenStr);
      console.log(decoded);
      this.setNeedToResetPassword(decoded);
      switch (decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]) {
        case "SysAdmin":
          this.isSysadmin = true;
          this.userType = UserType.SysAdmin;
          break;
        case "CustomerUser":
          this.isSysadmin = false;
          this.setCompanyId(decoded);
          this.userType = UserType.CustomerUser;
          break;
        case "TimeReportingUser":
          this.isSysadmin = false;
          this.setCompanyId(decoded);
          this.userType = UserType.TimeReportingUser;
          break;
        case "PunchClockUser":
          this.isSysadmin = false;
          this.setCompanyId(decoded);
          this.userType = UserType.PunchClockUser;
          break;
        case "DeveloperUser":
          this.isSysadmin = false;
          this.setCompanyId(decoded);
          this.userType = UserType.DeveloperUser;
          break;
      }
    }
  }

  setCompanyId(decoded: any): void {
    const companyIdText = decoded["Company_Id"];
    console.log(companyIdText);
    if (companyIdText) {
      this.companyId = parseInt(companyIdText);
    }
  }

  setNeedToResetPassword(decoded: any): void {
    const needToResetPasswordText = decoded["RequierNewPassword"];
    console.log(needToResetPasswordText);
    if (needToResetPasswordText) {
      this.needToResetPassword = JSON.parse(needToResetPasswordText);
    }
  }

  navigateToDefultPageForUserType(): void {
    if(this.needToResetPassword){
      this.router.navigateByUrl('/account/reset-password');
      return;
    }

    switch (this.userType) {
      case UserType.SysAdmin:
        this.router.navigateByUrl('');
        break;
      case UserType.CustomerUser:
        this.router.navigateByUrl('');
        break;
      case UserType.TimeReportingUser:
        this.router.navigateByUrl('/punch-clock/tid-profil');
        break;
      case UserType.PunchClockUser:
        this.router.navigateByUrl('/punch-clock/plats-valjare');
        break;
      case UserType.DeveloperUser:
        this.router.navigateByUrl('/project/developer');
        break;
    }
  }

  public existValidToken(): boolean {
    const token = this.tokenValue;
    if (token == null || token == undefined) return false;
    return this.ChekisLoggdIn(token);
  }

  private ChekisLoggdIn(token: Token): boolean {
    if (token && token.experation) {
      var exDate = new Date(token.experation);
      console.log(exDate);
      if (exDate.getTime() > Date.now()) {
        return true;
      }
    }
    return false;
  }

}
