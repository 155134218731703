<div *ngIf="(apiLoaded | async)">
  <google-map height="400px" width="750px" [center]="center" [zoom]="zoom" (mapClick)="addMarker($event)">
    <map-marker *ngIf="markerPosition" [position]="markerPosition" [options]="markerOptions"></map-marker>
  </google-map>

  <div>Latitude: {{markerPosition?.lat}}</div>
  <div>Longitude: {{markerPosition?.lng}}</div>

</div>

<!-- hej
{{apiLoaded | async}} -->
