import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InvoiceService } from '../_services/invoice.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  constructor(private router: Router, public invoiceService: InvoiceService) {}
}
