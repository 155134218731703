import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { UploadeInvoiceComponent } from './uploade-invoice/uploade-invoice.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoiceEditorComponent } from './invoice-editor/invoice-editor.component';
import { InvoiceManagerRoutingModule } from './invoice-manager-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { SharedLibaryModule } from '../shared-libary/shared-libary.module';
import localeSV from '@angular/common/locales/sv';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReInvoiceEditorComponent } from './re-invoice-editor/re-invoice-editor.component';
import { ManualUploadComponent } from './manual-upload/manual-upload.component';
import { InvoiceViewerComponent } from './invoice-viewer/invoice-viewer.component';
import { FailedUploadesComponent } from './failed-uploades/failed-uploades.component';
import { ComparePriceComponent } from './compare-price/compare-price.component';
import { LatestPriceUpComponent } from './latest-price-up/latest-price-up.component';
import { CreateReInvoiceComponent } from './create-re-invoice/create-re-invoice.component';
import { ReInvoicesComponent } from './re-invoices/re-invoices.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PriceChangeIndecatorComponent } from './price-change-indecator/price-change-indecator.component';
import { ComparePriceIconComponent } from './compare-price-icon/compare-price-icon.component';
import { InvoiceExplainerComponent } from './invoice-explainer/invoice-explainer.component';
import { ReInvoiceListPerInvoiceComponent } from './re-invoice-list-per-invoice/re-invoice-list-per-invoice.component';
import { MatInputModule } from '@angular/material/input';
registerLocaleData(localeSV);

@NgModule({
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'sv-SE', // 'de' for Germany, 'fr' for France ...
    },
  ],
  declarations: [
    UploadeInvoiceComponent,
    InvoicesComponent,
    InvoiceEditorComponent,
    LayoutComponent,
    ReInvoiceEditorComponent,
    ManualUploadComponent,
    InvoiceViewerComponent,
    FailedUploadesComponent,
    ComparePriceComponent,
    LatestPriceUpComponent,
    CreateReInvoiceComponent,
    ReInvoicesComponent,
    PriceChangeIndecatorComponent,
    ComparePriceIconComponent,
    InvoiceExplainerComponent,
    ReInvoiceListPerInvoiceComponent,
  ],
  imports: [
    CommonModule,
    InvoiceManagerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedLibaryModule,
    MatPaginatorModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatTooltipModule,
    MatCardModule,
    MatDividerModule,
    MatDatepickerModule,
    MatSortModule,
    MatCheckboxModule,
    MatInputModule,
  ],
  exports: [LatestPriceUpComponent],
})
export class InvoiceManagerModule {}
