import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { InvoiceService } from '../_services/invoice.service';

@Component({
  selector: 'app-invoice-viewer',
  templateUrl: './invoice-viewer.component.html',
  styleUrls: ['./invoice-viewer.component.scss'],
})
export class InvoiceViewerComponent implements OnInit, OnDestroy {
  imageList: string[] = [];
  imageUrl: SafeUrl | null = null;
  currentImage: string = '';
  loding = false;

  @Input() invoiceId = 0;

  constructor(
    private invoiceService: InvoiceService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnDestroy(): void {
    this.invoiceService.extraWith = false;
  }

  ngOnInit(): void {
    this.invoiceService.extraWith = true;
    this.invoiceService
      .downloadFileImagesList(this.invoiceId)
      .subscribe((x) => {
        this.imageList = x;
        if (x.length > 0) {
          this.getImage(x[0]);
        }
      });
  }

  getImage(imageLink: string): void {
    this.loding = true;
    this.currentImage = imageLink;
    this.invoiceService.downloadFileImage(imageLink).subscribe(
      (data) => {
        console.log(data);
        let unsafeImageUrl = URL.createObjectURL(data.body);
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
        this.loding = false;
      },
      (error) => {
        console.log(error);
        this.loding = false;
      }
    );
  }
}
