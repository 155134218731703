import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guard/auth.guard';
import { RoleGuard } from './_guard/role.guard';
import { HomeComponent } from './home/home.component';
import { UserType } from './_models/user';

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const adminPanelModule = () => import('./admin-panel/admin-panel.module').then(x => x.AdminPanelModule);
const invoiceModule = () => import('./invoice-manager/invoice-manager.module').then(x => x.InvoiceManagerModule);
const productModule = () => import('./products/products.module').then(x => x.ProductsModule);
const projectsModule = () => import('./project/project.module').then(m => m.ProjectModule);
const punchClockModule = () => import('./project/punch-clock/punch-clock.module').then(m => m.PunchClockModule);

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [RoleGuard], data: {roles: [UserType.SysAdmin, UserType.CustomerUser] }},
  { path: 'account', loadChildren: accountModule },
  { path: 'admin', loadChildren: adminPanelModule, canActivate: [RoleGuard], data: {roles: [UserType.SysAdmin, UserType.CustomerUser] }},
  { path: 'invoice', loadChildren: invoiceModule, canActivate: [RoleGuard], data: {roles: [UserType.SysAdmin, UserType.CustomerUser] } },
  { path: 'product', loadChildren: productModule, canActivate: [RoleGuard], data: {roles: [UserType.SysAdmin, UserType.CustomerUser] } },
  { path: 'project', loadChildren: projectsModule, canActivate: [RoleGuard], data: {roles: [UserType.SysAdmin, UserType.CustomerUser, UserType.DeveloperUser] } },
  { path: 'punch-clock', loadChildren: punchClockModule, canActivate: [RoleGuard], data: {roles: [UserType.SysAdmin, UserType.CustomerUser, UserType.PunchClockUser, UserType.TimeReportingUser] } },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { enableTracing: true}
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
