import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToDisplay'
})
export class EnumToDisplayPipe implements PipeTransform {

  transform(value: number, enums: any): string {
    return enums[value];
  }

}
