import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ReInvoiceService } from '../_services/re-invoice.service';
import { Invoice } from 'src/app/_models/invoice';

@Component({
  selector: 'app-re-invoice-list-per-invoice',
  templateUrl: './re-invoice-list-per-invoice.component.html',
  styleUrls: ['./re-invoice-list-per-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ReInvoiceListPerInvoiceComponent implements OnInit {
  reInvoices: Invoice[] = [];

  @Input() invoiceId: number | undefined;

  constructor(private reinvoiceService: ReInvoiceService) {}

  ngOnInit(): void {
    this.getReInvoices();
  }

  getReInvoices(): void {
    if (this.invoiceId == undefined) return;
    console.log(this.invoiceId);
    this.reinvoiceService
      .getAllReInvoiceByInvoice(this.invoiceId)
      .subscribe((x) => (this.reInvoices = x));
  }
}
