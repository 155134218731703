import { Time } from '@angular/common';
import { Component, HostListener, Inject } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss']
})
export class DateTimePickerComponent {

  date: Date | null = null;
  time: string | null = null;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    dateTime: number
  }, private mdDialogRef: MatDialogRef<DateTimePickerComponent>) {
    console.log(data);
    if(data && data.dateTime){
      console.log(data.dateTime);
      var date = new Date(data.dateTime * 1000);
      this.date = date;
      //this.time = { hours: date.getHours(), minutes: date.getMinutes() } as Time;
      this.time = date.getHours() + ':' + date.getMinutes();
    }else{
      this.date = new Date(Date.now());
      this.time = this.date.getHours() + ':' + this.date.getMinutes();
    }
    console.log(this.date);

  }

  addEvent(event: MatDatepickerInputEvent<Date>): void {
    this.date = event.value;
  }

  getUnixTime() {
    console.log('getUnixTime');
    if (this.date == null) return false;
    if (this.time == null) return false;
    //console.log(this.date);
    //console.log(this.time);
    let t = this.time.split(':');
    let time = { hours: +t[0], minutes: +t[1] } as Time;
    //console.log(time);
    //console.log(this.date.getTime());
    //console.log(this.getIsoSimpelString(this.date, time));
    var date = Date.parse(this.getIsoSimpelString(this.date, time));
    // let date: Date  =  structuredClone(this.date);
    // date.setHours(this.time.hours, this.time.minutes);
    //console.log(date);
    var unixtime = date / 1000;
    //console.log(unixtime);
    return unixtime;

  }

  getIsoString(date: Date, time: Time): string {
    return date.getFullYear() + '-' + (date.getMonth() + 1) + '-'
      + date.getDate() + 'T' + this.time + ':00.000' + this.getOffset(date);
  }

  getIsoSimpelString(date: Date, time: Time): string {
    return date.getFullYear() +
      '-' + this.zeroPad((date.getMonth() + 1), 2) +
      '-' + this.zeroPad(date.getDate(), 2) +
      'T' + this.time + ':00.000';
  }

  getOffset(date: Date): string {
    const totalMinutes = 0 - date.getTimezoneOffset();
    console.log(totalMinutes);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (totalMinutes == 0) {
      return 'Z';
    } else if (totalMinutes > 0) {
      return '+' + this.zeroPad(hours, 2) + ':' + this.zeroPad(minutes, 2);
    } else {
      return '' + this.zeroPad(hours, 2) + ':' + this.zeroPad(minutes, 2);
    }
  }

  zeroPad(num: number, places: number): string {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  public cancel() {
    this.close(false);
  }

  public close(value: any) {
    this.mdDialogRef.close(value);
  }

  public confirm() {
    this.close(this.getUnixTime());
  }

  @HostListener("keydown.esc")
  public onEsc() {
    this.close(false);
  }

}
