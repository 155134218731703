import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-price-type-selector',
  templateUrl: './price-type-selector.component.html',
  styleUrls: ['./price-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceTypeSelectorComponent implements OnInit {
  priceType = 0;

  @Input() preSelected = 0;
  @Input() disabled = false;

  @Output() priceTTypeChange = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.priceType = this.preSelected;
  }

  selected() {
    this.priceTTypeChange.emit(this.priceType);
  }

}
