import { SharedLibaryModule } from 'src/app/shared-libary/shared-libary.module';
import { InvoiceManagerModule } from './invoice-manager/invoice-manager.module';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule, isDevMode, ErrorHandler } from '@angular/core';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertComponent } from './alert/alert.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { JwtInterceptor } from './_interceptor/jwt.interceptor';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { GooglemapsLibaryModule } from './googlemaps-libary/googlemaps-libary.module';
import { MatMenuModule } from '@angular/material/menu';
import { NavButtonMenuComponent } from './nav-button-menu/nav-button-menu.component';

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    HomeComponent,
    NavBarComponent,
    SpinnerComponent,
    NavButtonMenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InvoiceManagerModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    SharedLibaryModule,
    GooglemapsLibaryModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_CURRENCY_CODE, useValue: 'SEK'
    },
    {
      provide: LOCALE_ID, useValue: 'sv-SE'
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'sv-SE'
    },
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService
    }
  ],
  bootstrap: [AppComponent],
  exports: [
    SpinnerComponent
  ]
})
export class AppModule { }
