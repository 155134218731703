import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../../_services/base.service';
import { MessageService } from '../../_services/message.service';
import { Invoice, InvoiceAppliedRow, Order, OrderRow, OrderSubRow, Retailor } from '../../_models/invoice';
import { environment } from 'src/environments/environment';
import { TableWrapper } from '../../_models/tableWrapper';
import { Price } from '../../_models/price';
import { FileInformation } from '../../_models/fileInformation';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends BaseService {


  public extraWith = false;

  constructor(
    private http: HttpClient,
    public messageService: MessageService,
    public dialog: MatDialog
  ) {
    super(messageService, dialog);
  }

  public downloadFile(id: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/Invoice/DownloadFile/` + id, { observe: 'response', responseType: 'blob' }) // , { responseType: 'blob' as 'json' }, { responseType: 'blob'}
      .pipe(
        map((res) => {
          console.log(res);
          const fileNameStr = res.headers.get('content-disposition')?.split('\'') || [];
          let data = {
            file: new Blob([res.body || '{}'], { type: res.headers.get('Content-Type') || '{}' }),
            filename: fileNameStr[fileNameStr.length - 1]
          }
          return data;
        }),
        catchError(this.handleError('DownloadFile'))
      );
  }

  public downloadFileImage(invoiceId: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('imageLink', invoiceId);
    //${invoiceId.replace(/\//g, "%2F")}
    return this.http.get(`${environment.apiUrl}/api/Invoice/DownloadFileImage?` + params.toString(), { observe: 'response', responseType: 'blob' })
      .pipe(
        catchError(this.handleError('downloadFileImage'))
      );
  }

  public downloadFileImagesList(invoiceId: number): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/api/Invoice/DownloadFileImagesList/${invoiceId}`)
      .pipe(
        catchError(this.handleError<string[]>('downloadFileImagesList'))
      );
  }

  public downloadErrorInvoicesList(): Observable<FileInformation[]> {
    return this.http.get<FileInformation[]>(`${environment.apiUrl}/api/Invoice/DownloadErrorInvoicesList`)
      .pipe(
        catchError(this.handleError<FileInformation[]>('DownloadErrorInvoicesList2'))
      );
  }

  public deletErrorInvoice(filename: string): Observable<void> {
    let params = new HttpParams();
    params = params.set('filename', filename);
    return this.http.delete<void>(`${environment.apiUrl}/api/Invoice/DeletErrorInvoice?` + params.toString())
      .pipe(
        catchError(this.handleError<void>('deletErrorInvoice'))
      );
  }

  public downloadErrorInvoice(filename: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('filename', filename);
    //${invoiceId.replace(/\//g, "%2F")}
    return this.http.get(`${environment.apiUrl}/api/Invoice/DownloadErrorInvoice?` + params.toString(), { observe: 'response', responseType: 'blob' })
      .pipe(
        map((res) => {
          console.log(res);
          const fileNameStr = res.headers.get('content-disposition')?.split('\'') || [];
          let data = {
            file: new Blob([res.body || '{}'], { type: res.headers.get('Content-Type') || '{}' }),
            filename: fileNameStr[fileNameStr.length - 1]
          }
          return data;
        }),
        catchError(this.handleError('DownloadErrorInvoice'))
      );
  }

  public doInvoiceExist(companyId: number, retailor: number, invoiceNr: string): Observable<boolean> {
    return this.http.get<boolean>(`${environment.apiUrl}/api/Invoice/DoInvoiceExist/${companyId}/${retailor}/${invoiceNr}`)
      .pipe(
        catchError(this.handleError<boolean>('doInvoiceExist'))
      );
  }

  public deleteInvoice(id: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiUrl}/api/Invoice/Delete/` + id)
      .pipe(
        catchError(this.handleError<void>('deleteInvoice'))
      );
  }

  public findinvoices(companyID: number, retailor: number = 0, filter: string, sortOrder: string, sortCol: string, pageNumber: number, pageSize: number): Observable<TableWrapper> {
    let params = new HttpParams()
      .set('companyId', companyID.toString())
      .set('filter', filter)
      .set('sortOrder', sortOrder)
      .set('sortCol', sortCol)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());
    if (retailor) {
      params = params.set('retailor', retailor.toString());
    }
    return this.http.get<TableWrapper>(`${environment.apiUrl}/api/Invoice/findinvoices`, {
      params: params
    }).pipe(
      catchError(this.handleError<TableWrapper>('findinvoices'))
    );
  }

  public proccesInvoice(id: number): Observable<void> {
    return this.http.get<void>(`${environment.apiUrl}/api/Invoice/ProccesInvoice/` + id)
      .pipe(
        catchError(this.handleError<void>('ProccesInvoice'))
      );
  }

  public getInvoice(id: number): Observable<Invoice> {
    return this.http.get<Invoice>(`${environment.apiUrl}/api/Invoice/GetInvoice/` + id)
      .pipe(
        catchError(this.handleError<Invoice>('GetInvoice'))
      );
  }

  public getInvoiceNrBySubRowId(subRowId: number): Observable<string> {
    return this.http.get<string>(`${environment.apiUrl}/api/Invoice/GetInvoiceNrBySubRowId/` + subRowId)
      .pipe(
        catchError(this.handleError<string>('GetInvoiceNrBySubRowId'))
      );
  }

  public getInvoiceByCompany(id: number): Observable<Invoice[]> {
    let url = `${environment.apiUrl}/api/Invoice/GetInvoicesByCompany/`;
    if (id > 0) {
      url += id;
    }
    return this.http.get<Invoice[]>(url)
      .pipe(
        catchError(this.handleError<Invoice[]>('GetInvoice'))
      );
  }

  public UpploadeInvoice(formData: FormData, retailor: Retailor, compnyId: number): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/api/Invoice/UpploadeInvoice/` + retailor + '/' + compnyId, formData)
      .pipe(
        catchError(this.handleError<boolean>('UpploadeInvoice'))
      );
  }

  public UpploadeInvoices(formData: FormData, retailor: Retailor, compnyId: number): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/api/Invoice/UpploadeInvoices/` + retailor + '/' + compnyId, formData)
      .pipe(
        catchError(this.handleError<boolean>('UpploadeInvoice'))
      );
  }

  public createInvoiceFromModel(invoice: Invoice): Observable<number> {
    return this.http.post<number>(`${environment.apiUrl}/api/Invoice/CreateInvoiceFromModel/`, invoice)
      .pipe(
        catchError(this.handleError<number>('CreateInvoiceFromModel'))
      );
  }

  public updateTotalsOnInvoice(invoice: Invoice, calculateOldTotal: boolean) {
    let total = 0;
    let oldTotal = 0;
    for (let i1 = 0; i1 < invoice.orders.length; i1++) {
      const order = invoice.orders[i1];
      for (let i2 = 0; i2 < order.rows.length; i2++) {
        const orderRow = order.rows[i2];
        for (let i3 = 0; i3 < orderRow.subRows.length; i3++) {
          this.updateValuesSubRow(orderRow.subRows[i3], calculateOldTotal);
          if (!orderRow.subRows[i3].newTotal) continue;
          total += orderRow.subRows[i3].newTotal;
          oldTotal += orderRow.subRows[i3].totalReadFromInvoice;
        }
      }
    }
    for (let index = 0; index < invoice.invoiceAppliedRows.length; index++) {
      total += this.calculateInvoiceAppliedRow(invoice.invoiceAppliedRows[index], calculateOldTotal);
      if (calculateOldTotal) {
        oldTotal += invoice.invoiceAppliedRows[index].newTotal;
      } else {
        oldTotal += invoice.invoiceAppliedRows[index].totalReadFromInvoice;
      }
    }
    invoice.newTotal = total;
    if (calculateOldTotal) {
      invoice.total = oldTotal;
    }
  }

  private calculateInvoiceAppliedRow(row: InvoiceAppliedRow, calculateOldTotal: boolean) {
    let total = 0;
    if (row.aPrice && row.amount) {
      total = row.amount * row.aPrice;
    }
    row.newTotal = total;

    if (calculateOldTotal) {
      row.totalReadFromInvoice = total;
    }

    return total;
  }

  private updateValuesSubRow(subRow: OrderSubRow, calculateOldTotal: boolean) {
    let newTotal = 0;
    let oldTotal = 0;
    if (subRow.newPrice) {
      newTotal = subRow.newPrice.priceOfTransaction * subRow.amount;
    } else if (subRow.customerPrice.aPrice && subRow.amount) {
      newTotal = subRow.customerPrice.aPrice * subRow.amount;
    }
    if (subRow.customerPrice.priceOfTransaction && subRow.amount) {
      oldTotal = subRow.customerPrice.priceOfTransaction * subRow.amount;
    }

    if (subRow.rentalTime) {
      newTotal = newTotal * subRow.rentalTime;
      oldTotal = oldTotal * subRow.rentalTime;
    }

    if (subRow.customerDiscount) {
      newTotal = newTotal * (1 - (subRow.customerDiscount / 100));
    }
    subRow.newTotal = newTotal;
    if (calculateOldTotal) {
      subRow.totalReadFromInvoice = oldTotal;
    }
  }

  //Chanche order
  addOrder(invoice: Invoice) {
    let o = {} as Order;
    o.rows = [];
    this.addOrderRow(o);
    invoice.orders.push(o);
  }

  addOrderRow(order: Order) {
    let or = {} as OrderRow;
    or.subRows = [];
    this.addOrderSubRow(or);
    order.rows.push(or);
  }

  addOrderSubRow(orderRow: OrderRow) {
    let osr = {} as OrderSubRow;
    osr.customerPrice = {} as Price;
    osr.totalReadFromInvoice = 0;
    orderRow.subRows.push(osr);
  }

  addInvoiceRow(invoice: Invoice) {
    invoice.invoiceAppliedRows.push({} as InvoiceAppliedRow);
  }

  deleteOrder(invoice: Invoice, order: Order) {
    const index = invoice.orders.findIndex(x => x == order);
    if (index > -1) {
      invoice.orders.splice(index, 1);
    }
  }

  deleteOrderRow(invoice: Invoice, orderRow: OrderRow) {
    let orderIndex = -1;
    let orderRowIndex = -1;
    for (let index = 0; index < invoice.orders.length; index++) {
      let i = invoice.orders[index].rows.findIndex(x => x == orderRow);
      if (i > -1) {
        orderIndex = index;
        orderRowIndex = i;
      }
    }
    if (orderRowIndex > -1) {
      invoice.orders[orderIndex].rows.splice(orderRowIndex, 1);
    }
  }

  deleteOrderSubRow(invoice: Invoice, orderSubRow: OrderSubRow) {
    let orderIndex = -1;
    let orderRowIndex = -1;
    let orderSubRowIndex = -1;
    for (let index = 0; index < invoice.orders.length; index++) {
      for (let index2 = 0; index2 < invoice.orders[index].rows.length; index2++) {
        let i = invoice.orders[index].rows[index2].subRows.findIndex(x => x == orderSubRow);
        if (i > -1) {
          orderIndex = index;
          orderRowIndex = index2;
          orderSubRowIndex = i;
        }
      }
    }
    if (orderRowIndex > -1) {
      invoice.orders[orderIndex].rows[orderRowIndex].subRows.splice(orderSubRowIndex, 1);
    }
  }

  deleteInvoiceAppliedRow(invoice: Invoice, invoiceAppliedRow: InvoiceAppliedRow) {
    const index = invoice.invoiceAppliedRows.findIndex(x => x == invoiceAppliedRow);
    if (index > -1) {
      invoice.invoiceAppliedRows.splice(index, 1);
    }
  }
}
