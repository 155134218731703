import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fromUtcDate'
})
export class FromUtcDatePipe extends DatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) locale: string) {
    super(locale);
  }

  transform(value: any, format?: string, timezone?: string, locale?: string): any {
    if (!value) {
      return '';
    }

    return super.transform(value * 1000, format, timezone, locale);

  }

}
