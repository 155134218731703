import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/_services/message.service';
import { Retailor } from 'src/app/_models/invoice';
import { InvoiceService } from '../_services/invoice.service';

@Component({
  selector: 'app-uploade-invoice',
  templateUrl: './uploade-invoice.component.html',
  styleUrls: ['./uploade-invoice.component.scss'],
})
export class UploadeInvoiceComponent implements OnInit {
  retailors = Retailor;
  selectedRetailors: Retailor = -1;
  selectedCompanyCompanyId = -1;

  constructor(
    private invoiceService: InvoiceService,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {}

  uploadeInvoice(files: any): void {
    let fileToUpload = <File>files[0];
    let formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    this.messageService.setSpinner(true);
    this.invoiceService
      .UpploadeInvoice(
        formData,
        this.selectedRetailors,
        this.selectedCompanyCompanyId
      )
      .subscribe(
        (x) => {
          this.messageService.endSpinnerWithMessage('Fil nu uppladdad');
        },
        (error) => {
          this.messageService.endSpinnerWithMessage(error);
        }
      );
  }

  uploadeInvoices(files: any): void {
    let formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      let fileToUpload = <File>files[index];
      formData.append('file', fileToUpload, fileToUpload.name);
    }

    this.messageService.setSpinner(true);
    this.invoiceService
      .UpploadeInvoices(
        formData,
        this.selectedRetailors,
        this.selectedCompanyCompanyId
      )
      .subscribe(
        (x) => {
          this.messageService.endSpinnerWithMessage('Fil nu uppladdad');
        },
        (error) => {
          this.messageService.endSpinnerWithMessage(error);
        }
      );
  }
}
