import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '../_services/account.service';
import { UserType } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    private router: Router,
    private accountService: AccountService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.isUserLogdIn()) {
      this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
      return false
    }
    console.log('user is logd in');
    if (this.isUserInaprovedRole(route.data.roles as Array<UserType>)) {
      this.accountService.navigateToDefultPageForUserType();
      return false;
    }

    return true;

  }

  isUserInaprovedRole(roles: Array<UserType>): boolean {
    let index = roles.findIndex(x => x === this.accountService.userType)
    return index == -1;
  }

  isUserLogdIn() {
    const user = this.accountService.tokenValue;
    if (!user) return false;
    if (!user.token) return false;
    return true;
  }

}
