import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PriceChangeDirection } from 'src/app/_models/invoice';

@Component({
  selector: 'app-price-change-indecator',
  templateUrl: './price-change-indecator.component.html',
  styleUrls: ['./price-change-indecator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceChangeIndecatorComponent {
  @Input() priceChangeDirection: PriceChangeDirection | undefined;
}
