import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/_services/account.service';
import { Retailor } from 'src/app/_models/invoice';
import { PriceDevelopment } from 'src/app/_models/priceDevelopment';
import { PriceDevelopmentService } from '../_services/price-development.service';

@Component({
  selector: 'app-latest-price-up',
  templateUrl: './latest-price-up.component.html',
  styleUrls: ['./latest-price-up.component.scss'],
})
export class LatestPriceUpComponent implements OnInit {
  priceDevelopments: PriceDevelopment[];
  retailors = Retailor;

  constructor(
    private priceDevelopmentService: PriceDevelopmentService,
    public accountService: AccountService
  ) {
    this.priceDevelopments = [];
  }

  ngOnInit(): void {
    if (!this.accountService.isSysadmin) {
      this.priceDevelopmentService
        .getPriceDevelopmentByCostomer(this.accountService.companyId)
        .subscribe((x) => (this.priceDevelopments = x));
    }
  }
}
