<div *ngIf="show" class="container">
  <div *ngIf="!priviusRow">Finns inget föregånde pris</div>
  <div *ngIf="!compareProducts">Finns inget konkurernade pris</div>
  <div *ngIf="invoice">
    <span>
      Faktura nr
      <a
        href="/invoice/invoice-editor/{{ invoice.invoiceId }}"
        target="_blank"
        >{{ invoice.invoiceNr }}</a
      >
    </span>
    <span style="margin-left: 1rem">
      {{ invoice.invoiceDate | date }}
    </span>
  </div>
  <div *ngIf="priviusRow" class="invoice-secondrow-container">
    <div></div>
    <div>
      <div *ngIf="priviusRow.isRental">
        {{ priviusRow.rentalTime }}
        {{ priviusRow.rentalType | enumToDisplay : priceTypes }}
      </div>
    </div>
    <div>
      {{ priviusRow.amount }}
      {{ priviusRow.customerPrice.priceType | enumToDisplay : priceTypes }}
    </div>
    <div>
      {{ priviusRow.customerPrice.priceOfTransaction | currency }}
    </div>
    <div class="d-flex">
      <div
        [ngClass]="{ 'bg-yellow': !priviusRow.hasNewPrice }"
        style="margin-left: auto"
      >
        <div *ngIf="priviusRow.hasNewPrice">
          {{ priviusRow.newPrice.aPrice | currency }}
        </div>
        <div *ngIf="!priviusRow.hasNewPrice">
          {{ priviusRow.customerPrice.aPrice | currency }}
        </div>
      </div>
    </div>
    <div>
      <div *ngIf="priviusRow.percentageDifferenceOfPrice != undefined">
        {{ priviusRow.percentageDifferenceOfPrice * 100 | number : "1.0-0" }}%
      </div>
    </div>
    <div>
      {{ priviusRow.totalReadFromInvoice | currency }}
    </div>
    <div>
      {{ priviusRow.newTotal | currency }}
    </div>
    <div>
      {{ priviusRow.newTotal - priviusRow.totalReadFromInvoice | currency }}
    </div>
  </div>
  <div *ngIf="compareProducts && compareProducts.length > 0">
    <div
      class="compare-product-container"
      style="text-decoration-line: underline"
    >
      <div>Leverantör</div>
      <div>Art.nr</div>
      <div>Benämning</div>
      <div>Brutto á-pris / Enhet</div>
      <div>Datum</div>
    </div>
    <div *ngFor="let product of compareProducts">
      <div class="compare-product-container">
        <div>
          <span style="font-weight: bold">{{
            product.retailor | enumToDisplay : retailors
          }}</span>
        </div>
        <div>
          {{ product.articleNo }}
        </div>
        <div>
          <span style="font-style: italic">{{ product.productName }}</span>
        </div>
        <div>
          {{ product.prices[0].aPrice | currency }} /
          {{ product.prices[0].priceType | enumToDisplay : priceTypes }}
        </div>
        <div>
          {{ product.prices[0].dateOfPrice | date }}
        </div>
      </div>
    </div>
  </div>
</div>
