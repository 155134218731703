import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-action-icon-buttons',
  templateUrl: './action-icon-buttons.component.html',
  styleUrls: ['./action-icon-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ActionIconButtonsComponent {

  @Input() edit: boolean = false;
  @Input() isNewProduct: boolean = false;
  @Output() saveClick = new EventEmitter();
  @Output() cancelClick = new EventEmitter();
  @Output() editClick = new EventEmitter();
  @Output() deleteClick = new EventEmitter();

  constructor() { }


  save(): void {
    this.saveClick.emit();
  }

  cancel(): void {
    this.cancelClick.emit();
  }

  editClickEvent(): void {
    this.editClick.emit();
  }

  delete(): void {
    this.deleteClick.emit();
  }
}
