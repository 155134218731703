import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AlertComponent } from '../alert/alert.component';
import { ConfirmationDialogComponent } from '../shared-libary/confirmation-dialog/confirmation-dialog.component';
import { DeletDialogComponent } from '../shared-libary/delet-dialog/delet-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class MessageService {

  alertRef: MatDialogRef<AlertComponent> | undefined;
  dialogRef: MatDialogRef<ConfirmationDialogComponent> | undefined;
  deletDialogRef: MatDialogRef<DeletDialogComponent> | undefined;

  public loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  interval: any;
  messages: string[] = [];

  constructor(
    public dialog: MatDialog) {

  }

  public open(options: { title: any; message: any; cancelText: any; confirmText: any; }) {
    this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText
      }
    });
  }

  public confirmed(): Observable<any> {
    if (this.dialogRef === undefined) {
      const observable = new BehaviorSubject("initial value");
      return observable;
    } else {
      return this.dialogRef.afterClosed().pipe(take(1), map(res => {
        return res;
      }));
    }
  }

  public deletConfirmation(): Observable<any> {
    this.deletDialogRef = this.dialog.open(DeletDialogComponent, {
      data: {
        title: 'Varnig',
        message: 'Är du säcker på att du vill ta bort?',
        cancelText: 'Avbryt',
        confirmText: 'Delet'
      }
    });
    return this.deletDialogRef.afterClosed().pipe(take(1), map(res => {
      return res;
    }));
  }

  add(message: string) {
    console.log(message);
    this.messages.push(message);
  }

  clear() {
    this.messages = [];
  }

  showMessage() {
    if (this.alertRef == undefined) {
      let msg = this.messages.pop();
      if (msg) this.showAlertDialog(msg);
    }
  }

  showAlert(message: string){
    this.add(message);
    this.showMessage();
  }

  endSpinnerWithMessage(message: string) {
    this.setSpinner(false);
    this.showAlert(message);
  }

  //#region spinner
  setSpinner(boolean: boolean): void {
    if (boolean && !this.interval && !this.loadingSubject.value) {
      this.interval = setTimeout(() => {
        this.loadingSubject.next(true);
        this.clearTimeout();
      }, 1000);
    } else if (!boolean) {
      this.clearTimeout();
      this.loadingSubject.next(false);
    }
  }

  clearTimeout() {
    if (this.interval) {
      clearTimeout(this.interval);
    }
    this.interval = undefined;
  }
  //#endregion

  private showAlertDialog(msessage: string): void {
    this.alertRef = this.alertDialog(msessage);
    this.alertRef.afterClosed().subscribe(result => {
      this.alertRef = undefined;
      this.showMessage();
    });
  }

  alertDialog(msessage: string): MatDialogRef<AlertComponent> {
    let alertRef = this.dialog.open(AlertComponent, {
      disableClose: false,
      // width: '500px',
      // height: '500px'
    });
    alertRef.componentInstance.confirmMessage = msessage;
    return alertRef;
  }
}
