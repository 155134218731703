import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, Subject } from 'rxjs';
import { AccountService } from 'src/app/_services/account.service';
import { Retailor } from 'src/app/_models/invoice';
import { ReInvoiceDataSource } from '../ReInvoiceDataSource';
import { ReInvoiceService } from '../_services/re-invoice.service';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  templateUrl: './re-invoices.component.html',
  styleUrls: ['./re-invoices.component.scss'],
})
export class ReInvoicesComponent implements OnInit {
  displayedColumns: string[] = [];
  dataSource: ReInvoiceDataSource;
  retailors = Retailor;
  companyId = 0;
  selectedRetailor = 0;
  searchTextChanged = new Subject<string>();
  lastSerchString = '';

  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private ReInvoiceService: ReInvoiceService,
    public accountService: AccountService
  ) {
    if (this.accountService.isSysadmin) {
      this.displayedColumns = [
        'customerReInvoiceNr',
        'invoiceNr',
        'invoiceDate',
        'retailor',
        'total',
        'company_Id',
      ];
    } else {
      this.displayedColumns = [
        'customerReInvoiceNr',
        'invoiceNr',
        'invoiceDate',
        'retailor',
        'total',
      ];
    }
    this.dataSource = new ReInvoiceDataSource(this.ReInvoiceService);
  }

  ngOnInit(): void {
    this.searchTextChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((val) => {
        if (val !== this.lastSerchString) {
          this.lastSerchString = val;
          this.load();
        }
      });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(tap(() => this.load()))
      .subscribe();
    this.load();
  }

  clerFilter() {
    this.lastSerchString = '';
    this.load();
  }

  load() {
    let direction;
    let col;
    if (this.sort && this.sort.direction) {
      direction = this.sort.direction;
      col = this.sort.active;
    }

    this.dataSource.load(
      this.companyId,
      this.selectedRetailor,
      this.lastSerchString,
      direction,
      col
    );
  }
}
