import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable, Observer, fromEvent, merge, of, throwError } from 'rxjs';
import { AlertComponent } from '../alert/alert.component';
import { MessageService } from './message.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  alertRef: MatDialogRef<AlertComponent> | undefined;
  public intlCollator: Intl.Collator;
  private isOnline = false;

  constructor(
    public messageService: MessageService,
    public dialog: MatDialog) {
    this.intlCollator = new Intl.Collator('sv', { usage: 'sort' });
    this.createOnline$().subscribe(isOnline => this.isOnline = isOnline);
  }

  public writeLineIfDev(s: any): void {
    const logg = localStorage.getItem('logg');
    if (!environment.production || (logg && logg.length > 0)) {
      console.log(s);
    }
  }


  /**
 * Handle Http operation that failed.
 * Let the app continue.
 *
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      const msg = this.getError(error);
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${msg}`);


      return throwError(msg);
      // Let the app keep running by returning an empty result.
      //return of(result as T);
    };
  }

  getError(e: any): string {
    if (typeof e === 'string') {
      return e;
    } else if (e instanceof ErrorEvent) {
      return e.message;
    } else if (e.error instanceof ErrorEvent) {
      return e.error.message;
    } else {
      if (this.isOnline) {
        return 'An error occurred:';
      } else {
        return 'Du är offline, förfrågan till servern misslyckades.';
      }
    }
  }

  private log(message: string) {
    // this.messageService.add(`InternetstiftelsenService: ${message}`);
    console.error('%c' + message, 'color:red');
  }

  public removeFromArray(arr: any[], item: any) {
    const index = arr.findIndex(x => x == item);
    if (index > -1) {
      arr.splice(index, 1);
    }
  }

  public downloadFileLocaly(data: any) {
    var url = window.URL.createObjectURL(data.file);
    var anchor = document.createElement("a");
    anchor.download = decodeURI(data.filename);
    anchor.href = url;
    anchor.click();
  }


  ifNullThenUndefind(obj: any, prop: string) {
    if (obj[prop] == null) {
      obj[prop] = undefined
    }
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }
}
