<div>
  <h2>Underlag</h2>
  <div *ngFor="let reInvoices of reInvoices">
    <a routerLink="/invoice/re-invoice-editor/{{ reInvoices.invoiceId }}"
      >{{ reInvoices.customerInvoiceNr }}-{{
        reInvoices.customerReInvoiceNr
      }}</a
    >
  </div>
</div>
