import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimpelMapComponent } from './simpel-map/simpel-map.component';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { SearchLocationMapComponent } from './search-location-map/search-location-map.component';
import { SearchAddresFiealdComponent } from './search-addres-fieald/search-addres-fieald.component';
import { SharedLibaryModule } from '../shared-libary/shared-libary.module';



@NgModule({
  declarations: [
    SimpelMapComponent,
    SearchLocationMapComponent,
    SearchAddresFiealdComponent
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    SharedLibaryModule
  ],
  exports: [
    SimpelMapComponent,
    SearchLocationMapComponent,
    SearchAddresFiealdComponent
  ]
})
export class GooglemapsLibaryModule { }
