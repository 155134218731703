<div class="dialog-container">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <div mat-dialog-content>
    {{ data.message }}
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="cancel()">{{ data.cancelText }}</button>
    <button mat-raised-button color="warn" (click)="confirm()">{{ data.confirmText }}</button>
  </div>
</div>
