import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat } from 'rxjs';
import { first } from 'rxjs/operators';
import { TimeService } from '../time.service';

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateService {

  constructor(appRef: ApplicationRef, updates: SwUpdate,
    private timeService: TimeService) {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    console.log('CheckForUpdateService constructor');
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const every30s$ = this.timeService.getIntervalInS(30);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, every30s$);

    every30s$.subscribe(async () => {
      console.log('every30SOnceAppIsStable');
      try {
        const updateFound = await updates.checkForUpdate();
        console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });
  }
}
