export enum PriceType {
  None = -1, M = 1, MM = 2, Ton = 3, Kg = 4, hg = 5, g = 6, L = 7, M2 = 8, M3 = 9, St = 10, HundraSt = 11,
  Förpakning = 12, Ask = 13, Paket = 14, Kartong = 15, Säck = 16, Skiva = 17, Set = 18, Bunt = 19,
  Påse = 20, Par = 21, Låda = 22, Rulle = 23, Burk = 24, Flaska = 25, PAT = 26, Pall = 27, Hink = 28,
  Tub = 29, STS = 30, Ark = 31, Bal = 32, Vecka = 33, Dag = 34, Timme = 35, Minut = 36, Fat = 37,
  Rör = 39, PRT = 40, SB = 41
}

export interface Price {
  priceId: number,
  product_Id: number,
  aPrice: number,
  priceType: number,
  priceReadInstant: number,
  priceOfTransaction: number,
  discountOfAPrice: number,
  dateOfPrice: number,
  trustedPrice: boolean
  company_Id: number;
}
