import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { ProductService } from 'src/app/products/_services/product.service';
import { Product } from 'src/app/_models/product';

@Component({
  selector: 'app-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.scss'],
})
export class ProductSelectorComponent implements OnInit {
  @Input() label = 'Produkt';
  textValue = '';
  @Input() retailorId = -1;
  @Input() disabled = false;

  @Output() SelectedProdEvent = new EventEmitter<Product>();
  @Output() textValueEvent = new EventEmitter<string>();

  posibolProduct: Product[] = [];
  filteredOptions!: Observable<Product[]>;
  searchTextChanged = new Subject<string>();
  lastSerchString = '';
  searching = false;

  constructor(private productService: ProductService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.searchTextChanged
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter((x) => x.length > 0)
      )
      .subscribe((val) => {
        this.search(val);
      });
  }

  search(val: string) {
    this.textValueEvent.emit(val);
    if (val === this.lastSerchString) return;
    this.lastSerchString = val;

    if (!this.retailorId || this.retailorId < 0) return;

    this.searching = true;
    this.productService
      .findPossibleMatchesOfProduct(val, this.retailorId)
      .subscribe((x) => {
        this.posibolProduct = x;
        this.searching = false;
        this.changeDetector.detectChanges();
      });
  }

  displayText(prod: Product): string {
    return prod.articleNo + ': ' + prod.productName;
  }

  selectOption(prod: Product) {
    this.textValue = ''; //prod.productName;    
    this.SelectedProdEvent.emit(prod);
    this.posibolProduct = [];
    this.lastSerchString = '';    
  }
}
