import { Price, PriceType } from "./price";
import { PriceDevelopment } from "./priceDevelopment";

export enum Retailor {
  Beijer = 1, Hyreslandslaget = 2, Nordstroms = 3, Optimera = 4, Bauhaus = 5, Derome = 6, Kami = 7, Benders = 8
}

export enum InvoiceStatus {
  Recevid = 1, Proccesd = 2, Arcived = 3, NeedsProcessing = 4
}

export enum PriceChangeDirection {
  Up = 1, Down = 2, NoChange = 3
}

export interface Invoice {
  invoiceId: number,
  company_Id: number,
  invoiceNr: string,
  invoiceDate: string,
  buyer: string,
  reciver: string,
  retailor: Retailor,
  orders: Order[],
  invoiceAppliedRows: InvoiceAppliedRow[],
  invoiceStatus: InvoiceStatus,
  readTime: number,
  lastUpdate: number,
  total: number,
  totalInvoiceRows: number,
  newTotalInvoiceRows: number,
  newTotal: number,
  customerInvoiceNr: number,
  customerReInvoiceNr: number
}

export interface Order {
  orderId: 0,
  invoice_Id: 0,
  orderNr: string,
  deliveryTime: string,
  buyerRef: string,
  sellerRef: string,
  marking: string,
  rows: OrderRow[]
  total: 0,
  newTotal: 0
}

export interface OrderRow {
  orderRowId?: 0,
  order_Id?: 0,
  articleNo?: string,
  productName: string,
  orderRowNumber?: 0,
  subRows: OrderSubRow[],
  total: 0,
  newTotal: 0
}

export interface OrderSubRow {
  orderSubRowId: number,
  orderRow_Id: number,
  amount: number,
  totalReadFromInvoice: number,
  rowAppliedDiscount: number,
  customerPrice: Price,
  newPrice: Price,
  isRental: boolean,
  rentalType: number,
  rentalTime: 0,
  newTotal: number,
  hasNewPrice: boolean,
  percentageDifferenceOfPrice: number,
  priceChangeDirection: PriceChangeDirection,
  compareProduct_Id:number,

  isCheckBoxed: boolean,
  customerDiscount?: number,
  showPriceDevelopment: boolean,
  priceDevelopment: PriceDevelopment
}

export interface InvoiceAppliedRow {
  invoiceAppliedRowId?: 0,
  invoice_Id?: 0,
  amount: 0,
  aPrice: 0,
  priceType: PriceType,
  PriceReadInstant: 0,
  totalReadFromInvoice: number,
  rowAppliedDiscount: 0,
  customerPrice: Price,
  articleNo: string,
  productName: string,
  newTotal: number,

  isCheckBoxed: boolean,
  customerDiscount?: number
}
