import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-compare-price-icon',
  templateUrl: './compare-price-icon.component.html',
  styleUrls: ['./compare-price-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparePriceIconComponent {
  @Input() compareProduct_Id: number | undefined;
}
